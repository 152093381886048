import React from "react";
import Header from "../Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import AboutSection from "./AboutSection";
import { useTranslation } from "react-i18next";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <div>
      <Header />
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("pageTitle1")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("pageTitle1")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-about5__area section-spacing">
          <div class="container">
            <div
              class="row g-5 gy-50 align-items-center wow fadeInUp"
              data-wow-delay=".4s"
            >
              <div class=" col-xxl-6 col-xl-6 col-lg-6 order-lg-0 order-1">
                <div class="df-video__box style-2 about_dtl">
                  <img
                    style={{ objectFit: "cover" }}
                    src="assets/img/newimages/img11.jpg"
                    alt="image not found"
                  />
                </div>
              </div>
              <div class=" col-xxl-6 col-xl-6 col-lg-5 order-lg-1 order-0">
                <div class="df-about3__content">
                  <div class="section__title-wrapper">
                    <span class="section__subtitle bg-lighter mt-30">
                      {t("aboutusSubTitle")}
                    </span>
                    <h2 class="section__title">{t("aboutusTitle1")}</h2>
                  </div>
                  <p class="mt-35 mb-35">
                    {t("aboutusDesc1")}
                    <br />
                    {t("aboutusDesc1A")}
                    <br />
                    {t("aboutusDesc1B")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AboutSection />
      </main>
      <Footer />
    </div>
  );
}

export default AboutUs;
