import React, { useState } from "react";
import Header from "../Header";
import Footer from "./Footer";
import { v4 } from "uuid";
import { storage, database } from "../Firebaseconfig";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import { ref as dbRef, push, set, onValue } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Contact() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    name: "",
    lname: "",
    email: "",
    number: "",
    address: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      !formData.name ||
      !formData.lname ||
      !formData.email ||
      !formData.number ||
      !formData.address ||
      !formData.message
    ) {
      toast.error("Please fill in all fields"); // Display error message
      setLoading(false);
      return;
    }
  
    setLoading(true);
  
    try {
      const formDataToSave = { ...formData };
  
      const contactsRef = dbRef(database, "contacts");
      const newContactRef = push(contactsRef);
  
      // Get the unique ID generated by push() method
      const contactId = newContactRef.key;
  
      // Add the unique ID to the contact data
      formDataToSave.id = contactId;
  
      // Save the contact data to the database
      await set(newContactRef, formDataToSave);
  
      toast.success("Form submitted successfully!"); // Display success message
      setLoading(false);

      // Reset form fields after successful submission
      setFormData({
        name: "",
        lname: "",
        email: "",
        number: "",
        address: "",
        message: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Try after sometime");
      setLoading(false);

    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div>
      <ToastContainer />
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <Header />
      <main>
        <section
          class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing"
          // style={{ backgroundImage: "url(assets/img/cont.avif)" }}
        >
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("pageTitle6")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("pageTitle6")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-contact__area section-spacing-top p-relative fix">
          <div class="circle-2"></div>
          <div class="circle-3"></div>
          <div class="container">
            <div class="row g-5">
              <div class="col-xxl-4 col-lg-6 col-md-6">
                <div
                  class="df-contact__box wow fadeInUp pdbmbb"
                  data-wow-delay=".4s"
                  // style={{ paddingBottom: "68px" }}
                >
                  <div class="df-contact__icon mb-20">
                    <i class="icon-051-telephone-1"></i>
                  </div>
                  <p class="df-contact__title mb-5">{t("contactFone")}</p>
                  <h4 class="df-contact__content">
                    <a href="tel:+919325502675">+91 9325502675</a>
                  </h4>
                </div>
              </div>
              <div class="col-xxl-4 col-lg-6 col-md-6">
                <div
                  class="df-contact__box wow fadeInUp"
                  data-wow-delay=".5s"
                  style={{ paddingBottom: "68px" }}
                >
                  <div class="df-contact__icon mb-20">
                    <i class="icon-052-email"></i>
                  </div>
                  <p class="df-contact__title mb-5">{t("contactEmail")}</p>
                  <h4 class="df-contact__content">
                    <a href="mailto:contact@bhutpurvsainikcanteen.com">
                      <span
                        className="emailclass"
                        // class="__cf_email__"
                        style={{
                          paddingBottom: "20px",
                        }}
                      >
                        contact@bhutpurvsainikcanteen.com{" "}
                      </span>
                    </a>
                  </h4>
                </div>
              </div>
              <div class="col-xxl-4 col-lg-6 col-md-6">
                <div class="df-contact__box wow fadeInUp" data-wow-delay=".6s">
                  <div class="df-contact__icon mb-20">
                    <i class="icon-030-pin"></i>
                  </div>
                  <p class="df-contact__title mb-5">{t("contactAdd")}</p>

                  <h4 class="df-contact__content">
                    <a
                      href="https://www.google.com/maps?q=Opposite+to+Shinde+Hospital,+Gurunagar,+bhadrawati+442902"
                      target="_blank"
                    >
                      {" "}
                      {t("contactAdd1")} <br /> {t("contactAdd2")}
                    </a>
                  </h4>
                </div>
              </div>
            </div>
            <div class="df-booking2__form-wrapper section-spacing-top">
              <div data-aos="fade-right" class="df-booking__video">
                <img src="assets/img/contactus.jpg" alt="image not found" />
                {/* <div class="df-video__play-btn pos-center">
                  <a
                    href="https://www.youtube.com/watch?v=0pZBJ7aJNy0"
                    class="play-btn popup-video"
                  >
                    <i class="icon-008-play-button"></i>
                  </a>
                </div> */}
              </div>
              <div data-aos="fade-left" class="df-booking2__form">
                <form action="#" onSubmit={handleSubmit}>
                  <div class="row gx-5">
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          placeholder="Your Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <input
                          type="text"
                          id="email"
                          name="lname"
                          value={formData.lname}
                          onChange={handleChange}
                          placeholder="Your Last Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          placeholder="Your Email"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <input
                          type="text"
                          id="number"
                          name="number"
                          value={formData.number}
                          onChange={handleChange}
                          placeholder="Your Number"
                        />
                      </div>
                    </div>
                    {/* <div class="col-md-6">
                            <div class="df-input-field">
                                <select name="service" id="service">
                                    <option value="subject">Subject</option>
                                    <option value="cleaning">Cleaning</option>
                                    <option value="plumbing">Plumbing</option>
                                </select>
                            </div>
                        </div> */}
                    <div class="col-md-12">
                      <div class="df-input-field">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          value={formData.address}
                          onChange={handleChange}
                          placeholder="House Address"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="df-input-field">
                        <textarea
                          type="text"
                          onChange={handleChange}
                          id="message"
                          name="message"
                          value={formData.message}
                          placeholder="Write Message Here..."
                        ></textarea>
                      </div>
                    </div>
                    {
loading ? <div className="loader-container">
  <div className="loader"></div>
</div>
:
<div class="col-md-12">
<div class="df-booking2__form-btn mt-0">
  <button type="submit" class="primary-btn">
    send now
    <span class="icon__box">
      <img
        class="icon__first"
        src="assets/img/icon/arrow-white.webp"
        alt="image not found"
      />
      <img
        class="icon__second"
        src="assets/img/icon/arrow-white.webp"
        alt="image not found"
      />
    </span>
  </button>
</div>
</div>
                    }
                 
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="df__map p-relative section-spacing-top">
            <div class="df__google-map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d234.16130289000952!2d79.11679914143456!3d20.109801154452214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd325539d1c2ee9%3A0x9b186756e24a2590!2sBHUTPURVA%20SAINIK%20CANTEEN%2C%20BHADRAVATI!5e0!3m2!1sen!2sin!4v1718346728323!5m2!1sen!2sin"
                width="1920"
                height="580"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
