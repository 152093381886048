import React from "react";
import Header from "../Header";
import Footer from "../Pages/Footer";
import VendorRegistretion from "./VendorRegistretion";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Vendor() {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("pageTitle2")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("pageTitle2")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-service5__area section-spacing-top">
          <div class="container">
            <div class="row g-50 align-items-center">
              <div class="col-lg-6 order-lg-0 order-1">
                <div class="df-service5__thumb vendor_abt">
                  <img
                    src="assets/img/newimages/img8.jpg"
                    alt="img not found"
                  />
                </div>
              </div>
              <div class="col-lg-6 order-lg-1 order-0">
                <div class="df-service5__content vendor_abt">
                  <div class="section__title-wrapper">
                    <span class="section__subtitle bg-lighter">
                      {t("vendorSubTitle")}
                    </span>
                    <h2 class="section__title">{t("vendorTitle1")}</h2>
                  </div>
                  <p class="mt-35 mb-35 scnd_vndr">{t("vendorDesc1")}</p>
                  <div class="df-service5__info">
                    <div class="df-service5__client-info">
                      <h4 class="df-service5__client-title mb-5">
                        {t("vendorTitle2")}{" "}
                      </h4>
                      <p class="df-service5__client-name">
                        {" "}
                        {t("vendorDesc2")}{" "}
                      </p>
                    </div>
                    <div class="df-service5__client-category">
                      <h4 class="df-service5__client-title mb-5">
                        {t("vendorTitle3")}{" "}
                      </h4>
                      <p>{t("vendorDesc3")} </p>
                    </div>
                    <div class="df-service5__meta-info">
                      <h4 class="df-service5__meta-title mb-5">
                        {t("vendorTitle4")}{" "}
                      </h4>
                      <p class="df-service5__meta-day">{t("vendorDesc4")} </p>
                    </div>
                    <div class="df-service5__meta-address">
                      <h4 class="df-service5__meta-title mb-5">
                        {t("vendorTitle5")}
                      </h4>
                      <p> {t("vendorDesc5")} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="df-policy__area  section-spacing-top vendor_list"
          style={{ paddingBottom: "40px" }}
        >
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-12">
                <div class="df-policy__wrapper df-policy__translate p-relative z-index-1">
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("vendorTitle6")}</h3>
                    <p>{t("vendorDesc6")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("vendorTitle7")} </h3>
                    <p>{t("vendorDesc7")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("vendorTitle8")}</h3>
                    <p>{t("vendorDesc8")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("vendorTitle9")}</h3>
                    <p>{t("vendorDesc9")}</p>
                  </div>
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("vendorTitle10")}</h3>
                    <p>{t("vendorDesc10")}</p>
                  </div>
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("vendorTitle11")}</h3>
                    <p>{t("vendorDesc11")}</p>
                    <ul>
                      <li>{t("vendorDesc11A")}</li>
                      <li>{t("vendorDesc11B")}</li>
                      <li>{t("vendorDesc11C")}</li>
                      <li>{t("vendorDesc11D")}</li>
                      <li>{t("vendorDesc11E")}</li>
                      <li>{t("vendorDesc11F")}</li>
                    </ul>
                  </div>
                  <div class="df-policy__contact">
                    <h3 class="df-policy__title df-policy__title-2">
                      {t("vendorTitle12")}
                    </h3>
                    <p>{t("vendorDesc12")}</p>

                    <ul>
                      <li>
                        {t("vendorDesc12A")}
                        <span>
                          <a>contact@bhutpurvsainikcanteen.com </a>
                        </span>
                      </li>
                    </ul>

                    <div class="df-policy__address">
                      <p class="mb-0">
                        <a>{t("vendorDesc12B")}</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          class="df-portfolio__area  p-relative fix"
          style={{ paddingBottom: "40px" }}
        >
          <div class="container">
            <div class="row g-5">
              <div class="col-lg-12">
                <div class="df-portfolio4__item-box">
                  <div data-aos="fade-right" class="df-portfolio4__item-thumb">
                    <a>
                      <img
                        style={{ height: "300px", width: "100%" }}
                        src="assets/img/newimages/img7.jpg"
                        alt="img not found"
                      />
                    </a>
                  </div>
                  <div data-aos="fade-left" class="df-portfolio4__item-content">
                    <div class="df-portfolio4__item-info">
                      {/* <a href="portfolio-details.html">
                        <span class="tag">Instalation</span>
                      </a> */}
                      <h4 class="df-portfolio4__item-title">
                        <a>{t("vendorTitle13")}</a>
                      </h4>
                      <p>{t("vendorDesc13")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="df-portfolio4__item-box">
                  <div
                    data-aos="fade-right"
                    class="df-portfolio4__item-content"
                  >
                    <div class="df-portfolio4__item-info">
                      <h4 class="df-portfolio4__item-title">
                        <a>{t("vendorTitle14")}</a>
                      </h4>
                      <p>{t("vendorDesc14")}</p>
                    </div>
                  </div>
                  <div data-aos="fade-left" class="df-portfolio4__item-thumb">
                    <a>
                      <img
                        style={{ height: "300px", width: "100%" }}
                        src="assets/img/newimages/img6.jpg"
                        alt="img not found"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Vendor;
