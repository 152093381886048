import React from "react";
import Header from "../Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Bscmegamart() {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("pageTitle4")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("pageTitle4")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-blog__area section-spacing p-relative fix">
          <div class="circle-2"></div>
          <div class="circle-3"></div>
          <div class="container">
            <div className="row">
              {" "}
              <div class="col-12">
                <div
                  class="section__title-wrapper section-title-spacing"
                  style={{ padding: "0 18px" }}
                >
                  <h3 class="section__title mb-4">{t("bscmegamrtTitle1")} </h3>
                  <div className="row">{t("bscmegamrtDesc1")}</div>
                </div>{" "}
              </div>
            </div>

            <div class="row g-5">
              <div class="col-lg-12">
                <div class="col-lg-12">
                  <div class="df-portfolio4__item-box">
                    <div
                      data-aos="fade-right"
                      class="df-portfolio4__item-content"
                    >
                      <div class="df-portfolio4__item-info">
                        <h4 class="df-portfolio4__item-title">
                          <a>{t("bscmegamrtTitle2")}</a>
                        </h4>
                        <p>{t("bscmegamrtDesc3")}</p>
                      </div>
                    </div>
                    <div data-aos="fade-left" class="df-portfolio4__item-thumb">
                      <a>
                        <img
                          className="imageresponsive"
                          style={{ width: "100%" }}
                          src="assets/img/newimages/img13.jpg"
                          alt="img not found"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="df-portfolio4__item-box">
                  <div data-aos="fade-right" class="df-portfolio4__item-thumb">
                    <a>
                      <img
                        className="imageresponsive"
                        style={{ width: "100%" }}
                        src="assets/img/newimages/img12.jpg"
                        alt="img not found"
                      />
                    </a>
                  </div>
                  <div data-aos="fade-left" class="df-portfolio4__item-content">
                    <div class="df-portfolio4__item-info">
                      <h4 class="df-portfolio4__item-title">
                        <a>{t("bscmegamrtTitle3")} </a>
                      </h4>
                      <p>{t("bscmegamrtDesc3")}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default Bscmegamart;
