import React from "react";
import { useTranslation } from "react-i18next";
function HomeVenderList() {
  const { t } = useTranslation();
  return (
    <>
      <section class="bf-product-cat-area section-spacing-bottom pt-40">
        <div class="container">
          <div class="row">
            <div  class="col-lg-7">
              <div
                class="bf-product-cat cat-xl mb-25 wow "
              
              >
                <div class="bf-product-cat-content-wrapper">
                  <div
                    class="bf-product-cat-bg product-bg-1"
                    style={{
                      backgroundImage: "url('assets/img/homevender.jpg')",
                    }}
                  ></div>
                  <div class="bf-product-cat-content">
                    <h4 class="bf-product-cat-title">550+</h4>
                    <p>{t("homeVendor1")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div  class="col-lg-5">
              <div
                class="bf-product-cat mb-25 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".3s"
              >
                <div class="bf-product-cat-content-wrapper">
                  <div
                    class="bf-product-cat-bg product-bg-2"
                    style={{
                      backgroundImage: "url('assets/img/IMG_3740_58.jpg')",
                    }}
                  ></div>
                  <div class="bf-product-cat-content">
                    <h4 class="bf-product-cat-title">356+</h4>
                    <p>{t("homeVendor2")}</p>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-bottom" class="col-lg-12">
              <div
                class="bf-product-cat cat-xl mb-25 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".1s"
              >
                <div class="bf-product-cat-content-wrapper">
                  <div
                    class="bf-product-cat-bg product-bg-1"
                    style={{
                      backgroundImage: "url('assets/img/Happycos.jpeg')",
                    }}
                  ></div>
                  <div class="bf-product-cat-content">
                    <h4 class="bf-product-cat-title">500+</h4>
                    <p>{t("homeVendor3")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeVenderList;
