import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        <section className="df-footer__area ">
          <div className="footer__widgets-area">
            <div className="container">
              <div className="footer__widgets-wrapper-2 widgets-5">
                <div className="footer__widget">
                  <div className="mb-40">
                    <div className="df-footer__logo mb-30">
                      <a href="#">
                        <img
                          src="assets/img/logo/footer_logo.png"
                          alt="image not found"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="social-links">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/share/pwv1uw4PTX3KBYYj/?mibextid=qi2Omg">
                          <i className="icon-023-facebook-app-symbol"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/bhutpurvasainikcanteen/">
                          <i className="icon-025-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer__widget">
                  <h4 className="footer__widget-title">{t("footerLinks1")}</h4>
                  <div className="footer__links underline">
                    <ul>
                      <li>
                        <Link to="/about">{t("headerlist2")}</Link>
                      </li>
                      <li>
                        <Link to="/vendor">{t("headerlist3")}</Link>
                      </li>
                      <li>
                        <Link to="/OurFrenchice">{t("headerlist4")}</Link>
                      </li>
                      <li>
                        <Link to="/bscmegamart">{t("headerlist5")}</Link>
                      </li>
                      <li>
                        <Link to="/blog">{t("headerlist6")}</Link>
                      </li>
                      <li>
                        <Link to="/contact">{t("headerlist7")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer__widget">
                  <h4 className="footer__widget-title">{t("footerLinks2")}</h4>
                  <div className="footer-meta">
                    <i className="icon-051-telephone-1"></i>
                    <p>
                      {" "}
                      <a href="tel:+919325502675">+91 9325502675</a>
                    </p>
                  </div>
                  <div className="footer-meta">
                    <i className="icon-052-email"></i>
                    <p>
                      <a
                        href="mailto:contact@bhutpurvsainikcanteen.com"
                        style={{ wordBreak: "break-word" }}
                      >
                        contact@bhutpurvsainikcanteen.com{" "}
                      </a>
                    </p>
                  </div>

                  <div className="footer-meta mb-15">
                    <i className="icon-030-pin"></i>
                    <p>
                      <a href="https://www.google.com/maps?q=Opposite+to+Shinde+Hospital,+Gurunagar,+bhadrawati+442902">
                        {t("footerAdd1")} <br /> {t("footerAdd2")}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="hr1"></div>
          </div>
          <div className="copyright__area p-relative">
            <div className="container">
              <div className="copyright-content__wrapper">
                <div className="copyright__text">
                  <p>
                    ©<Link to="/">{t("footerbottomLside")}</Link>
                  </p>
                </div>
                <div className="copyright__nav">
                  <ul>
                    <li>
                      <Link to="/privacypolicy">{t("footerbottomRside1")}</Link>
                    </li>
                    <li>
                      <Link to="/termsconditions">
                        {t("footerbottomRside2")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
}

export default Footer;
