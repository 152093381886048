import React from "react";
import { useTranslation } from "react-i18next";
function HomeFrenchiiseSection() {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <section class="df-benifits__area section-spacing">
        <div class="container">
          <div
            class="row align-items-center justify-content-between g-50 "
            // data-wow-delay=".3s"
          >
            <div class="col-xl-7">
              <div
                data-aos="fade-right"
                class="section__title-wrapper section-title-spacing about_franchise"
              >
                <span class="section__subtitle bg-lighter">
                  {" "}
                  {t("homeFranchiseSectionSubTitle")}
                </span>
                <h2 class="section__title">
                  {t("homeFranchiseSectionTitle")}
                  <br />
                  {t("homeFranchiseSectionTitle1")}
                </h2>
                <p class="mt-35 mb-35">{t("homeFranchiseSectionDesc")}</p>
              </div>
              <div class="df-benifits__wrapper">
                <div class="df-about4__feature-list mb-45">
                  <ul>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeFranchiseSectionDesc1A")}</p>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeFranchiseSectionDesc1B")}</p>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeFranchiseSectionDesc1C")}</p>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeFranchiseSectionDesc1D")}</p>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeFranchiseSectionDesc1E")}</p>
                    </li>
                  </ul>
                </div>
                <div class="bf-benifits__btn text-xl-start text-md-center mt-50">
                  <a href="https://wa.me/9325502675" class="primary-btn">
                    {t("homeFranchiseSectionDesc1F")}
                    <span class="icon__box">
                      <img
                        class="icon__first"
                        src="assets/img/icon/arrow-white.webp"
                        alt="image not found"
                      />
                      <img
                        class="icon__second"
                        src="assets/img/icon/arrow-white.webp"
                        alt="image not found"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div class="col-xl-5">
              <div data-aos="fade-left" class="df-benifits__thumb">
                <img
                  style={{ height: "100%", width: "100%" }}
                  src="assets/img/franchiseimage.jpg"
                  alt="image not found"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeFrenchiiseSection;
