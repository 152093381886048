import React from "react";
import { useTranslation } from "react-i18next";
const AboutSection = () => {
  const { t } = useTranslation();
  return (
    <main>
      {" "}
      <section
        class="df-blog__area  p-relative fix about_main_wrp"
        style={{ paddingBottom: "100px" }}
      >
        <div class="container">
          <div class="row g-5">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div class="df-blog3__box">
                <div data-aos="fade-right" class="df-blog3__content">
                  <h3 class="df-blog3__title">
                    <a>{t("aboutusTitle2")}</a>
                  </h3>
                  <p>{t("aboutusDesc2")}</p>
                </div>
                <div data-aos="fade-left" class="df-blog3__thumb">
                  <a>
                    <img
                      style={{ width: "100%" }}
                      src="assets/img/newimages/img1.jpg"
                      alt="image not found"
                    />
                  </a>
                </div>
              </div>
              <div>
                <h3 class="df-blog3__title">
                  <a>{t("aboutusTitle3")}</a>
                </h3>
                <p>{t("aboutusDesc3")}</p>
              </div>
              <div className="pt-30">
                <h3 class="df-blog3__title mb-10">
                  <a>{t("aboutusTitle4")}</a>
                </h3>
                <p>{t("aboutusDesc4")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default AboutSection;
