import React,{useState , useEffect} from 'react'
import './Admin.css'
import { FaSearchLocation } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";
import { ref as storageRef, uploadBytes, getDownloadURL, listAll } from '../Firebaseconfig'
import { ref as dbRef, push, set , onValue } from '../Firebaseconfig';
import { storage, database } from '../Firebaseconfig';
import { Link  , useNavigate} from 'react-router-dom';
import Hamburger from 'hamburger-react'
import Sidebar from './Sidebar';

function AdminHome() {

    const [usersData, setUsersData] = useState([]);
    const [usermsg , setUsersMsg] = useState([]);
    const [franchice , setFranchice] = useState([]);
    const [isOpen, setOpen] = useState(false)

    const navigate = useNavigate();

    useEffect(() => {
        // Function to fetch user data from Firebase Realtime Database
        const fetchUsersData = async () => {
          try {
            const usersRef = dbRef(database, 'vendors');
    
            // Listen for changes in the "users" collection
            onValue(usersRef, (snapshot) => {
              const userData = snapshot.val();
              if (userData) {
                // Convert the object of user data into an array
                const usersArray = Object.values(userData);
                setUsersData(usersArray);
              } else {
                setUsersData([]);
              }
            });
          } catch (error) {
            console.error('Error fetching users data: ', error);
          }
        };

        const fetchUsersData1 = async () => {
          try {
            const usersRef = dbRef(database, 'contacts');
    
            // Listen for changes in the "users" collection
            onValue(usersRef, (snapshot) => {
              const userData = snapshot.val();
              if (userData) {
                // Convert the object of user data into an array
                const usersArray = Object.values(userData);
                setUsersMsg(usersArray);
              } else {
                setUsersMsg([]);
              }
            });
          } catch (error) {
            console.error('Error fetching users data: ', error);
          }
        };
    
        const fetchFranchiceData = async () => {
          try {
            const usersRef = dbRef(database, 'Frenchise');
    
            // Listen for changes in the "users" collection
            onValue(usersRef, (snapshot) => {
              const userData = snapshot.val();
              if (userData) {
                // Convert the object of user data into an array
                const usersArray = Object.values(userData);
                setFranchice(usersArray);
              } else {
                setFranchice([]);
              }
            });
          } catch (error) {
            console.error('Error fetching users data: ', error);
          }
        };
        fetchUsersData();
        fetchUsersData1();
        fetchFranchiceData();
      }, []); 

 console.log(usersData)
 console.log(usermsg)


  const SendData=(data)=>{
   console.log(data)
   navigate('/vendorprofile',{state:data});

  }


  const handleLogout=()=>{
    navigate('/');
    window.localStorage.clear();

  }


  return (
   <>
 

<body >
<Sidebar/>
 
  <section class="home-section">
    <nav>
      <div class="sidebar-button">
        <i class='bx bx-menu sidebarBtn'></i>
        <span class="dashboard">Bhutpurva Sainik Canteen</span>
      </div>
      <div class="mobileham">
      <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      { isOpen &&
 <div className='mobileSidbar'>
 <div style={{position:"relative" , left: "9px"}} className='mobileham'>      <Hamburger toggled={isOpen} toggle={setOpen}   />
</div>
 <div className='mobileNavcontainer'>

<ul class="nav-links">
 <li className='my-1' style={{color:'white'}}>
   <a href="#" class="active">
     <RxDashboard  class='mx-3' />
     <span class="links_name mx-3">Dashboard</span>
   </a>
 </li>
 <li className='my-1'  style={{color:'white'}}>
   <Link to="/formList">
 <SiGoogleforms class='mx-3'/>
     <span class="links_name mx-3">Registration forms</span>
   </Link>
 </li>

 <li className='my-1'    style={{color:'white'}}>
   <a href="#">

  <CiCircleList  
  class='mx-3'
  />        <span class="links_name mx-3">Total Enquiry</span>
   </a>
 </li>


 <li className='my-1' style={{color:'white'}}>
   <a href="#">
     <IoIosSettings 
     class='mx-3'
     />
     <span class="links_name mx-3">Setting</span>
   </a>
 </li>
 <li className='my-1' style={{color:'white'}}>
   <a onClick={handleLogout}>
<CiLogout  
class='mx-3'
/>

     <span class="links_name mx-3">Logout</span>
   </a>
 </li>

</ul>
 </div>

</div>
      }
     
     {!isOpen &&
      <div class="search-box" >
        <input type="text" placeholder="Search..." />
      </div>
}
      <div class="profile-details">
        <img src="https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1114445501.jpg" alt="" />
        <span class="admin_name">Admin </span>
        <i class='bx bx-chevron-down' ></i>
      </div>
    </nav>

    <div class="home-content">
      <div class="overview-boxes">
        <div class="box">
          <div class="right-side">
            <div class="box-topic"> Vendor Froms</div>
            <div class="number" style={{fontSize:'26px'}}>{usersData.length}</div>
            <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">Up from today</span>
            </div>
          </div>
          <i class='bx bx-cart-alt cart'></i>
        </div>
        <div class="box">
          <div class="right-side">
            <div class="box-topic">Total Enquiry</div>
            <div class="number">{usermsg && usermsg.length}</div>
            <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">Up from yesterday</span>
            </div>
          </div>
          <i class='bx bxs-cart-add cart two' ></i>
        </div>
        <div class="box">
          <div class="right-side">
            <div class="box-topic">Frachise Form</div>
            <div class="number">{franchice && franchice.length}</div>
            <div class="indicator">
              <i class='bx bx-up-arrow-alt'></i>
              <span class="text">Up from yesterday</span>
            </div>
          </div>
          <i class='bx bx-cart cart three' ></i>
        </div>
        <div class="box">
          <div class="right-side">
            <div class="box-topic">Total Return</div>
            <div class="number"></div>
            <div class="indicator">
              <i class='bx bx-down-arrow-alt down'></i>
              <span class="text">Down From Today</span>
            </div>
          </div>
          <i class='bx bxs-cart-download cart four' ></i>
        </div>
      </div>

      <div class="sales-boxes">
      <div class="recent-sales box">
  <div class="title">Recent Vendors</div>
  <table class="table table-striped" style={{marginTop:'25px'}}>
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Firm Name</th>
        <th scope="col">Registration Date</th>
        <th scope="col">Details</th>
      </tr>
    </thead>
    <tbody>
      {usersData.slice(0,5).map((user, index) => (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{user.firmName}</td>
          <td>{user.
DateOfRegistretion}</td>
        <td onClick={() => SendData(user)}>
  
  <button type="button" class="btn btn-primary">View details</button>
</td>

        </tr>
      ))}
    </tbody>
  </table>
  <div class="button">
    <Link to="/formList">See All</Link>
  </div>
</div>

    
      </div>
    </div>
  </section>


</body>

   
   </>

  )
}

export default AdminHome