import React, { useState, useEffect } from "react";
import "./Admin.css";
import { FaSearchLocation } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { IoRefreshCircleSharp } from "react-icons/io5";

import { GrUserAdmin } from "react-icons/gr";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "../Firebaseconfig";
import { ref as dbRef, push, setDatabase, onValue } from "../Firebaseconfig";
import { storage, database } from "../Firebaseconfig";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer , toast } from "react-toastify";
import Hamburger from "hamburger-react";
import Sidebar from "./Sidebar";
function AdminEnquiry() {
  const [usersData, setUsersData] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [reloade , setReloade] = useState('Refresh');

  const navigate = useNavigate();

  const fetchUsersData = async () => {
    try {
      const usersRef = dbRef(database, "contacts");

      // Listen for changes in the "users" collection
      onValue(usersRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          // Convert the object of user data into an array
          const usersArray = Object.values(userData);
          setUsersData(usersArray);
        } else {
          setUsersData([]);
        }
      });
    } catch (error) {
      console.error("Error fetching users data: ", error);
    }
  };

  useEffect(() => {
    // Function to fetch user data from Firebase Realtime Database
  

    // Call the function to fetch user data
    fetchUsersData();
  }, []);

  console.log(usersData);


  const handleLogout = () => {
    navigate("/");
    window.localStorage.clear();
  };


  const DeleteContact=(userId)=>{
   console.log(userId)
   try {
    if(userId){
      const userRef = dbRef(database, `contacts/${userId}`);
      setDatabase(userRef, null);
      console.log(userRef)
      toast.success("Contact  Deleted Successfully");
    }
    else{
      toast.warning("Please try again later");

    }
    
  } catch (error) {
    console.error("Error deleting user: ", error);
  }
  }


  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const filteredUsers = usersData.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const handleRefresh = () => {
    const icon = document.querySelector('.refresh-icon');
    setReloade('Please wait')
    if (icon) {
      icon.classList.add('rotate');
      fetchUsersData();
      setTimeout(() => {
        icon.classList.remove('rotate');
        setReloade('Refresh')

      }, 1000); 
    }
  
    
  };
  

  return (
    <div>
      <body>
 <Sidebar/>

        <section class="home-section">
          <nav>
            <div class="sidebar-button">
              <i class="bx bx-menu sidebarBtn"></i>
              <span class="dashboard">Bhutpurva Sainik Canteen</span>
            </div>
            <div class="mobileham">
              <Hamburger toggled={isOpen} toggle={setOpen} />
            </div>
            {isOpen && (
              <div className="mobileSidbar">
                <div
                  style={{ position: "relative", left: "9px" }}
                  className="mobileham"
                >
                  {" "}
                  <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>
                <div className="mobileNavcontainer">
                  <ul class="nav-links">
                    <li className="my-1" style={{ color: "white" }}>
                      <a href="#" class="active">
                        <RxDashboard class="mx-3" />
                        <span class="links_name mx-3">Dashboard</span>
                      </a>
                    </li>
                    <li className="my-1" style={{ color: "white" }}>
                      <a href="#">
                        <SiGoogleforms class="mx-3" />
                        <span class="links_name mx-3">Registration forms</span>
                      </a>
                    </li>

                    <li className="my-1" style={{ color: "white" }}>
                      <a href="#">
                        <CiCircleList class="mx-3" />{" "}
                        <span class="links_name mx-3">Total order</span>
                      </a>
                    </li>

                    <li className="my-1" style={{ color: "white" }}>
                      <a href="#">
                        <IoIosSettings class="mx-3" />
                        <span class="links_name mx-3">Setting</span>
                      </a>
                    </li>
                    <li className="my-1" style={{ color: "white" }}>
                      <a onClick={handleLogout}>
                        <CiLogout class="mx-3" />

                        <span class="links_name mx-3">Logout</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {!isOpen && (
              <div class="search-box">
                <input
                  type="text"
                  placeholder="Search by firm name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            )}
            <div class="profile-details">
              <img
                src="https://www.shutterstock.com/image-vector/blank-avatar-photo-place-holder-600nw-1114445501.jpg"
                alt=""
              />
              <span class="admin_name">Admin </span>
              <i class="bx bx-chevron-down"></i>
            </div>
          </nav>

          <div class="home-content">
            <div class="overview-boxes"></div>

            <div class="sales-boxes">
              <div class="recent-sales box">
              <div>
              <button onClick={() => handleRefresh()}>
  <IoRefreshCircleSharp className="refresh-icon" style={{ color: 'green' }} />
  <span className="mx-3">{reloade}</span>  
</button>

                </div>

                <table
                  class="table table-striped"
                  style={{ marginTop: "25px" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Firm Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Number</th>
                      <th scope="col">Message</th>
                      <th scope="col">Action</th>

                      {/* <th scope="col">Details</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {currentUsers.map((user, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.number}</td>
                        <td>{user.message}</td>

                        <td> <button className="btn btn-danger" onClick={()=>DeleteContact(user.id)}>Delete Contact</button></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  className="pagination"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <button
                    className="btn btn-secondary"
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    Previous
                  </button>
                  <span className="mx-2">Page {currentPage}</span>
                  <button
                    className="btn btn-secondary"
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentUsers.length < usersPerPage}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </body>
    </div>
  );
}

export default AdminEnquiry;
