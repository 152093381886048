import React from "react";
import { useTranslation } from "react-i18next";
function HomeVendorPage() {
  const { t } = useTranslation();
  return (
    <>
      {" "}
      <section class="df-challenge__area pb-60 pt-0">
        <div class="container">
          <div class="row section-title-spacing">
            <div class="col-lg-4">
              <div class="section__title-wrapper">
                <span class="section__subtitle bg-lighter">
                  {t("homeVendorPageSubTitle")}
                </span>
                <h2 class="section__title">{t("homeVendorPageTitle")}</h2>
              </div>
            </div>
            <div class="col-lg-8 d-flex justify-content-end">
              <div class="df-challenge__content">
                <p class="mb-40 mt-40">{t("homeVendorPageDesc1")}</p>
                <div class="df-challenge__feature-list">
                  <ul>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeVendorPageDesc1A")}</p>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeVendorPageDesc1B")}</p>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeVendorPageDesc1C")}</p>
                    </li>
                    <li>
                      <span class="list-icon">
                        <i class="icon-058-check"></i>
                      </span>
                      <p> {t("homeVendorPageDesc1D")}</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeVendorPage;
