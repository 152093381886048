import React, { useState } from "react";
import { v4 } from "uuid";
import { storage, database } from "../Firebaseconfig";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import { ref as dbRef, push, set, onValue } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";
import Footer from "../Pages/Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function VendorRegistretion() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firmName: "",
    firmDescription: "",
    HeadOffice: "",
    BranchOffice: "",
    GodownAddress: "",
    Factory: "",
    OfficeNumber: "",
    OfficeLandLine: "",
    Email: "",
    Manufacturer: "",
    Trader: "",
    AuthorizedDestribuiter: "",
    TypeofStores: "",
    Listofstores: "",
    Dealership: "",
    directorsManaging: "",
    ProprietorManager: "",
    AddressofPartners: "",
    articalAccociets: null,
    amended: null,
    Registration: null,
    Contactname: "",
    contactDesignation: "",
    contacyPhone: "",
    ContactAddress: "",
    TexRegistration: null,
    TexclearanceCertificate: null,
    Customerswithaddress: "",
    Bankername: "",
    Bankeraddress: "",
    BankeraccountNo: "",
    WarrentyPeriod: "",
    WarrentyScoped: "",
    numberofemployess: "",
    minimmumrequreiment: "",
    foreingerStaff: "",
    trainingPrograme: "",
    RegistrationNdate: "",
    FactroyNumber: "",
    YeasrOFCommencment: "",
    LicenseNumber: "",
    AnnualTonOver: "",
    EnvoirmentClearence: "",
    MonthlyProductions: "",
    SpareCapacity: "",
    FuturePlan: "",
    BasicReaserch: "",
    productTested: null,
    ISEcertification: "",
    DateOfRegistretion:""
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const file = files ? files[0] : null;

    setFormData({
      ...formData,
      [name]: value,
      [`${name}File`]: file,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let errors = {};
    if (formData.firmName.trim() === "") {
      errors.firmName = "Please enter firm name.";
    }
    if (formData.HeadOffice.trim() === "") {
      errors.HeadOffice = "Please enter Head Office Add.";
    }
    if (formData.BranchOffice.trim() === "") {
      errors.BranchOffice = "Please enter Branch Office Add.";
    }
    if (formData.GodownAddress.trim() === "") {
      errors.GodownAddress = "Please enter Godown Add.";
    }
    if (formData.Factory.trim() === "") {
      errors.Factory = "Please enter Factory Name.";
    }
    if (formData.OfficeNumber.trim() === "") {
      errors.OfficeNumber = "Please enter Office Number.";
    }
    if (formData.OfficeLandLine.trim() === "") {
      errors.OfficeLandLine = "Please enter Landline No.";
    }
    if (formData.firmDescription.trim() === "") {
      errors.firmDescription = "Please enter firm Description.";
    }
    if (formData.Manufacturer.trim() === "") {
      errors.Manufacturer = "Please enter Manufacturer name.";
    }
    if (formData.Trader.trim() === "") {
      errors.Trader = "Please enter Trader name.";
    }
    if (formData.AuthorizedDestribuiter.trim() === "") {
      errors.AuthorizedDestribuiter =
        "Please enter Autherised Destributor name.";
    }
    if (formData.TypeofStores.trim() === "") {
      errors.TypeofStores = "Please enter Store Type.";
    }
    if (formData.Listofstores.trim() === "") {
      errors.Listofstores = "Please enter List Of Stores.";
    }
    if (formData.Dealership.trim() === "") {
      errors.Dealership = "Please enter Dealership name.";
    }
    if (formData.ProprietorManager.trim() === "") {
      errors.ProprietorManager = "Please enter Manager name.";
    }
    if (formData.AddressofPartners.trim() === "") {
      errors.AddressofPartners = "Please enter Partners Add.";
    }
    if (formData.directorsManaging.trim() === "") {
      errors.directorsManaging = "Please enter Managing Directors .";
    }
    if (formData.articalAccociets === "") {
      errors.articalAccociets = "Please enter Accociets artical.";
    }
    if (formData.amended === "") {
      errors.amended = "Please enter Amended.";
    }
    if (formData.Registration === "") {
      errors.Registration = "Please enter Registration.";
    }
    if (formData.Contactname.trim() === "") {
      errors.Contactname = "Please enter Contact name.";
    }
    if (formData.contactDesignation.trim() === "") {
      errors.contactDesignation = "Please enter Designation.";
    }
    if (formData.contacyPhone.trim() === "") {
      errors.contacyPhone = "Please enter Phone No.";
    }
    if (formData.ContactAddress.trim() === "") {
      errors.ContactAddress = "Please enter Address.";
    }
    if (formData.TexRegistration === "") {
      errors.TexRegistration = "Please enter Tax Registration.";
    }
    if (formData.TexclearanceCertificate === "") {
      errors.TexclearanceCertificate =
        "Please enter Tax clearance Certificate.";
    }
    if (formData.Customerswithaddress.trim() === "") {
      errors.Customerswithaddress = "Please enter Customers Add.";
    }
    if (formData.Bankername.trim() === "") {
      errors.Bankername = "Please enter Banker name.";
    }
    if (formData.Bankeraddress.trim() === "") {
      errors.Bankeraddress = "Please enter Banker Add.";
    }
    if (formData.BankeraccountNo.trim() === "") {
      errors.BankeraccountNo = "Please enter Banker Account No.";
    }
    if (formData.WarrentyPeriod.trim() === "") {
      errors.WarrentyPeriod = "Please enter Warrenty Period.";
    }
    if (formData.WarrentyScoped.trim() === "") {
      errors.WarrentyScoped = "Please enter Scope of Warrenty.";
    }
    if (formData.numberofemployess.trim() === "") {
      errors.numberofemployess = "Please enter number of employess.";
    }
    if (formData.minimmumrequreiment.trim() === "") {
      errors.minimmumrequreiment = "Please enter minimmum requreiment.";
    }
    if (formData.foreingerStaff.trim() === "") {
      errors.foreingerStaff = "Please enter Foreginer Staff.";
    }
    if (formData.trainingPrograme.trim() === "") {
      errors.trainingPrograme = "Please enter Training Programme.";
    }
    if (formData.RegistrationNdate.trim() === "") {
      errors.RegistrationNdate = "Please enter Registration date.";
    }
    if (formData.FactroyNumber.trim() === "") {
      errors.FactroyNumber = "Please enter Factroy No.";
    }
    if (formData.YeasrOFCommencment.trim() === "") {
      errors.YeasrOFCommencment = "Please enter Year OF Commencment.";
    }
    if (formData.AnnualTonOver.trim() === "") {
      errors.AnnualTonOver = "Please enter Annual TurnOver.";
    }
    if (formData.EnvoirmentClearence.trim() === "") {
      errors.EnvoirmentClearence = "Please enter Envoirment Clearence.";
    }
    if (formData.LicenseNumber.trim() === "") {
      errors.LicenseNumber = "Please enter License No.";
    }
    if (formData.MonthlyProductions.trim() === "") {
      errors.MonthlyProductions = "Please enter Monthly Productions.";
    }
    if (formData.SpareCapacity.trim() === "") {
      errors.SpareCapacity = "Please enter Spare Capacity.";
    }
    if (formData.BasicReaserch.trim() === "") {
      errors.BasicReaserch = "Please enter Basic Reaserch.";
    }
    if (formData.productTested === "") {
      errors.productTested = "Please enter Tested Product.";
    }
    if (formData.DateOfRegistretion === "") {
      errors.productTested = "Please enter Registration Date.";
    }
    if (formData.ISEcertification.trim() === "") {
      errors.ISEcertification = "Please enter ISE Certification.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.Email)) {
      errors.Email = "Please enter a valid email address.";
    }

    // If there are errors, set them and prevent form submission
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      setLoading(false);
      return;
    }

    // If all validations pass, you can submit the form
    console.log("Form data:", formData);

    try {
      const formDataToSave = { ...formData };

      for (const fieldName in formData) {
        if (fieldName.endsWith("File") && formData[fieldName]) {
          const file = formData[fieldName];
          const fileRef = storageRef(storage, `files/${file.name + v4()}`);
          await uploadBytes(fileRef, file);
          formDataToSave[fieldName.replace("File", "")] = await getDownloadURL(
            fileRef
          );
        }
      }

      const vendorsRef = dbRef(database, "vendors");
      const newVendorRef = push(vendorsRef);

      const vendorId = newVendorRef.key;

      formDataToSave.id = vendorId;

      set(newVendorRef, formDataToSave);

      toast("Form submitted successfully!");
      setLoading(false);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting form");
      setLoading(false);
    } finally {
      setLoading(false);
      setFormData({
        firmName: "",
        firmDescription: "",
        HeadOffice: "",
        BranchOffice: "",
        GodownAddress: "",
        Factory: "",
        OfficeNumber: "",
        OfficeLandLine: "",
        Email: "",
        Manufacturer: "",
        Trader: "",
        AuthorizedDestribuiter: "",
        TypeofStores: "",
        Listofstores: "",
        Dealership: "",
        directorsManaging: "",
        ProprietorManager: "",
        AddressofPartners: "",
        articalAccociets: null,
        amended: null,
        Registration: null,
        Contactname: "",
        contactDesignation: "",
        contacyPhone: "",
        ContactAddress: "",
        TexRegistration: null,
        TexclearanceCertificate: null,
        Customerswithaddress: "",
        Bankername: "",
        Bankeraddress: "",
        BankeraccountNo: "",
        WarrentyPeriod: "",
        WarrentyScoped: "",
        numberofemployess: "",
        minimmumrequreiment: "",
        foreingerStaff: "",
        trainingPrograme: "",
        RegistrationNdate: "",
        FactroyNumber: "",
        YeasrOFCommencment: "",
        LicenseNumber: "",
        AnnualTonOver: "",
        EnvoirmentClearence: "",
        MonthlyProductions: "",
        SpareCapacity: "",
        FuturePlan: "",
        BasicReaserch: "",
        productTested: null,
        ISEcertification: "",
        DateOfRegistretion:""
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <Header />
      {loading && (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      )}
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("headerlist3A")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("headerlist3A")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div
          class="vendor_form_main section-spacing"
          style={{ overflowY: "hidden" }}
        >
          <div class="row justify-content-center" style={{ width: "100%" }}>
            <div class="col-xl-8 col-lg-8 col-md-12">
              <div
                class="df-booking__form vendor_form vendor_frm_main"
                style={{
                  boxShadow:
                    " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                  padding: " 40px",
                  borderRadius: "15px",
                  marginLeft: "15px",
                }}
              >
                <form onSubmit={handleSubmit}>
                  <div class="row gx-5">
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="name">Name of the firm*</label>

                        <input
                          type="text"
                          id="name"
                          name="firmName"
                          onChange={handleChange}
                          value={formData.firmName}
                        />
                        {errors.firmName && (
                          <span className="error">{errors.firmName}</span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">E-Mail Address*</label>
                        <input
                          type="email"
                          id="address"
                          name="Email"
                          onChange={handleChange}
                          value={formData.Email}
                        />
                        {errors.Email && (
                          <span className="error">{errors.Email}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="number">ADDRESS: Regd./Head Office*</label>
                        <input
                          type="text"
                          id="number"
                          name="HeadOffice"
                          onChange={handleChange}
                          value={formData.HeadOffice}
                        />
                        {errors.HeadOffice && (
                          <span className="error">{errors.HeadOffice}</span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="">
                        <label for="date">Branch/Sales office*</label>
                        <input
                          type="text"
                          id="date"
                          class="bd-date-picker hasDatepicker"
                          name="BranchOffice"
                          onChange={handleChange}
                          value={formData.BranchOffice}
                        />
                        {errors.BranchOffice && (
                          <span className="error">{errors.BranchOffice}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="">
                        <label for="time">Godown Address*</label>
                        <input
                          type="text"
                          id="time"
                          name="GodownAddress"
                          onChange={handleChange}
                          value={formData.GodownAddress}
                        />
                        {errors.GodownAddress && (
                          <span className="error">{errors.GodownAddress}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Factory / Workshop*</label>
                        <input
                          type="text"
                          id="address"
                          name="Factory"
                          onChange={handleChange}
                          value={formData.Factory}
                        />{" "}
                        {errors.Factory && (
                          <span className="error">{errors.Factory}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Office Telephone No./Nos.*</label>
                        <input
                          type="number"
                          id="address"
                          name="OfficeNumber"
                          onChange={handleChange}
                          value={formData.OfficeNumber}
                        />{" "}
                        {errors.OfficeNumber && (
                          <span className="error">{errors.OfficeNumber}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Office Landline/Mobile Nos*</label>
                        <input
                          type="number"
                          id="address"
                          name="OfficeLandLine"
                          onChange={handleChange}
                          value={formData.OfficeLandLine}
                        />
                        {errors.OfficeLandLine && (
                          <span className="error">{errors.OfficeLandLine}</span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="text">
                          Brief Description of the Organization (i.e., History,
                          Total Area, Present Set-up, Future expansion plans,
                          Depts., Labs, etc)*
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="firmDescription"
                          onChange={handleChange}
                          value={formData.firmDescription}
                        />{" "}
                        {errors.firmDescription && (
                          <span className="error">
                            {errors.firmDescription}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="text">
                        Date of Registration(This is the date you applied for vendor registration).*
                        </label>
                        <input
                          type="date"
                          id="email"
                          name="DateOfRegistretion"
                          onChange={handleChange}
                          value={formData.DateOfRegistretion}
                        />{" "}
                        {errors.firmDescription && (
                          <span className="error">
                            {errors.DateOfRegistretion}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">REGISTRATION IS SOUGHT AS :</div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Manufacturer*</label>
                        <input
                          type="text"
                          id="address"
                          name="Manufacturer"
                          onChange={handleChange}
                          value={formData.Manufacturer}
                        />
                        {errors.Manufacturer && (
                          <span className="error">{errors.Manufacturer}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Trader*</label>
                        <input
                          type="text"
                          id="address"
                          name="Trader"
                          onChange={handleChange}
                          value={formData.Trader}
                        />
                        {errors.Trader && (
                          <span className="error">{errors.Trader}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Authorized Distributer*</label>
                        <input
                          type="text"
                          id="address"
                          name="AuthorizedDestribuiter"
                          onChange={handleChange}
                          value={formData.AuthorizedDestribuiter}
                        />
                        {errors.AuthorizedDestribuiter && (
                          <span className="error">
                            {errors.AuthorizedDestribuiter}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Name of the Mfrs. & Type of Stores*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="TypeofStores"
                          onChange={handleChange}
                          value={formData.TypeofStores}
                        />
                        {errors.TypeofStores && (
                          <span className="error">{errors.TypeofStores}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          List of stores for which registration is sought*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="Listofstores"
                          onChange={handleChange}
                          value={formData.Listofstores}
                        />
                        {errors.Listofstores && (
                          <span className="error">{errors.Listofstores}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          List of distributor / Dealership*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="Dealership"
                          onChange={handleChange}
                          value={formData.Dealership}
                        />
                        {errors.Dealership && (
                          <span className="error">{errors.Dealership}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">KIND OF OWNERSHIP :</div>

                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          If Single Owner, Name & Address of the Proprietor &
                          Manager,*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="ProprietorManager"
                          onChange={handleChange}
                          value={formData.ProprietorManager}
                        />
                        {errors.ProprietorManager && (
                          <span className="error">
                            {errors.ProprietorManager}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          If any If partnership, Name & Address of Partners ,
                          are there*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="AddressofPartners"
                          onChange={handleChange}
                          value={formData.AddressofPartners}
                        />
                        {errors.AddressofPartners && (
                          <span className="error">
                            {errors.AddressofPartners}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="df-input-field">
                        <label for="address">
                          If a limited Concern, Name & Address of Directors &
                          Managing Director*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="directorsManaging"
                          onChange={handleChange}
                          value={formData.directorsManaging}
                        />
                        {errors.directorsManaging && (
                          <span className="error">
                            {errors.directorsManaging}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">IS YOUR FIRM REGISTERED UNDER?</div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          The Indian Companies Act, 1956 as amended (attach copy
                          of Memorandum & Articles of Association)*
                        </label>
                        <input
                          type="file"
                          id="address"
                          name="articalAccociets"
                          style={{ padding: "10px" }}
                          onChange={handleChange}
                          value={formData.articalAccociets}
                        />
                        {errors.articalAccociets && (
                          <span className="error">
                            {errors.articalAccociets}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          The Indian Partnership Act,1932 as amended ( attach
                          Statement in register of firms showing names of
                          Partners)*
                        </label>
                        <input
                          type="file"
                          id="address"
                          name="amended"
                          onChange={handleChange}
                          value={formData.amended}
                          style={{ padding: "10px" }}
                        />
                        {errors.amended && (
                          <span className="error">{errors.amended}</span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="df-input-field">
                        <label for="address">
                          Indian Factories Act, 1950 (Registration No. & date to
                          be given) Any other Act*
                        </label>
                        <input
                          type="file"
                          id="address"
                          onChange={formData.Registration}
                          name="Registration"
                          style={{ padding: "10px" }}
                        />
                        {errors.Registration && (
                          <span className="error">{errors.Registration}</span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-12">
                      <div class="df-booking__form-btn mt-10">
                        FOR ANY FURTHER INFORMATION, PERSON(S) TO BE CONTACTED
                        WITH*
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Name*</label>
                        <input
                          type="text"
                          id="address"
                          onChange={handleChange}
                          value={formData.Contactname}
                          name="Contactname"
                        />
                        {errors.Contactname && (
                          <span className="error">{errors.Contactname}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Designation*</label>
                        <input
                          type="text"
                          id="address"
                          name="contactDesignation"
                          onChange={handleChange}
                          value={formData.contactDesignation}
                        />
                        {errors.contactDesignation && (
                          <span className="error">
                            {errors.contactDesignation}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Phone Number*</label>
                        <input
                          type="text"
                          id="address"
                          name="contacyPhone"
                          onChange={handleChange}
                          value={formData.contacyPhone}
                        />
                        {errors.contacyPhone && (
                          <span className="error">{errors.contacyPhone}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Address*</label>
                        <input
                          type="text"
                          id="address"
                          name="ContactAddress"
                          onChange={handleChange}
                          value={formData.ContactAddress}
                        />
                        {errors.ContactAddress && (
                          <span className="error">{errors.ContactAddress}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Sales Tax Registration No. (Attach latest copies of
                          GST, CST Certificates)*
                        </label>
                        <input
                          type="file"
                          id="address"
                          name="TexRegistration"
                          onChange={handleChange}
                          style={{ padding: "10px" }}
                        />
                        {errors.TexRegistration && (
                          <span className="error">
                            {errors.TexRegistration}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Income Tax Registration No (Attach latest income Tax
                          clearance Certificate)*
                        </label>
                        <input
                          type="file"
                          id="address"
                          onChange={handleChange}
                          name="TexclearanceCertificate"
                          style={{ padding: "10px" }}
                        />
                        {errors.TexclearanceCertificate && (
                          <span className="error">
                            {errors.TexclearanceCertificate}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-12">
                      LIST OF PRINCIPAL CUSTOMERS WITH ADDRESS
                    </div>
                    <div class="col-md-12">
                      <div class="df-input-field">
                        <label for="address">
                          List of Principal Customers with address*
                        </label>
                        <input
                          type="text"
                          id="address"
                          onChange={handleChange}
                          value={formData.Customerswithaddress}
                          name="Customerswithaddress"
                        />
                        {errors.Customerswithaddress && (
                          <span className="error">
                            {errors.Customerswithaddress}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">Bankers Name</div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Bankers Name*</label>
                        <input
                          type="text"
                          id="address"
                          name="Bankername"
                          onChange={handleChange}
                          value={formData.Bankername}
                        />
                        {errors.Bankername && (
                          <span className="error">{errors.Bankername}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Bankers Address.*</label>
                        <input
                          type="text"
                          id="address"
                          name="Bankeraddress"
                          onChange={handleChange}
                          value={formData.Bankeraddress}
                        />
                        {errors.Bankeraddress && (
                          <span className="error">{errors.Bankeraddress}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="df-input-field">
                        <label for="address">Bankers A/C No.*</label>
                        <input
                          type="text"
                          id="address"
                          name="BankeraccountNo"
                          onChange={handleChange}
                          value={formData.BankeraccountNo}
                        />
                        {errors.BankeraccountNo && (
                          <span className="error">
                            {errors.BankeraccountNo}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">
                      ARE YOU PROVIDING AFTER SALE SERVICES? IF SO, INDICATE
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Warranty Period.*</label>
                        <input
                          type="text"
                          id="address"
                          name="WarrentyPeriod"
                          onChange={handleChange}
                          value={formData.WarrentyPeriod}
                        />
                        {errors.WarrentyPeriod && (
                          <span className="error">{errors.WarrentyPeriod}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Scope of Warranty*</label>
                        <input
                          type="text"
                          id="address"
                          name="WarrentyScoped"
                          onChange={handleChange}
                          value={formData.WarrentyScoped}
                        />
                        {errors.WarrentyScoped && (
                          <span className="error">{errors.WarrentyScoped}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">
                      DETAILS OF MANAGERIAL & TECHNICAL PERSONNEL
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Total no. of employees, Administrative, Technical, QC
                          Inspectors, Skilled – unskilled personnel*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="numberofemployess"
                          onChange={handleChange}
                          value={formData.numberofemployess}
                        />
                        {errors.numberofemployess && (
                          <span className="error">
                            {errors.numberofemployess}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          The min. requirements, experience & qualification laid
                          down for quality control manager, Supervisors &
                          Inspection staff.*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="minimmumrequreiment"
                          onChange={handleChange}
                          value={formData.minimmumrequreiment}
                        />
                        {errors.minimmumrequreiment && (
                          <span className="error">
                            {errors.minimmumrequreiment}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Is any member of your staff a foreigner? If yes, give
                          details*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="foreingerStaff"
                          onChange={handleChange}
                          value={formData.foreingerStaff}
                        />
                        {errors.foreingerStaff && (
                          <span className="error">{errors.foreingerStaff}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Training Program of Staff*</label>
                        <input
                          type="text"
                          id="address"
                          name="trainingPrograme"
                          onChange={handleChange}
                          value={formData.trainingPrograme}
                        />
                        {errors.trainingPrograme && (
                          <span className="error">
                            {errors.trainingPrograme}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-12">
                      TYPE OF INDUSTRY: SMALL / MEDIUM / LARGE SCALE INDUSTRY
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          In case small Scale Industry, registration No. & date
                          with the Director of Industries with proof.*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="RegistrationNdate"
                          onChange={handleChange}
                          value={formData.RegistrationNdate}
                        />
                        {errors.RegistrationNdate && (
                          <span className="error">
                            {errors.RegistrationNdate}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address" style={{ fontSize: "13px" }}>
                          In case of Medium Scale/Large Scale Industry, Factory
                          number allotted by the Director General of Technical
                          Development.*
                        </label>
                        <input
                          type="text"
                          id="address"
                          onChange={handleChange}
                          value={formData.FactroyNumber}
                          name="FactroyNumber"
                        />
                        {errors.FactroyNumber && (
                          <span className="error">{errors.FactroyNumber}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="df-input-field">
                        <label for="address">
                          Year of commencement of manufacture of stores*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="YeasrOFCommencment"
                          onChange={handleChange}
                          value={formData.YeasrOFCommencment}
                        />
                        {errors.YeasrOFCommencment && (
                          <span className="error">
                            {errors.YeasrOFCommencment}
                          </span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-12">
                      MANUFACTURING CAPACITY AS APPROVED BY GOVERNMENT:
                    </div>

                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address" style={{ fontSize: "13px" }}>
                          Annual Turnover for last 3 years (Indicate company’s
                          financial year & give estimated value for current
                          year*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="AnnualTonOver"
                          onChange={handleChange}
                          value={formData.AnnualTonOver}
                        />
                        {errors.AnnualTonOver && (
                          <span className="error">{errors.AnnualTonOver}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Is environment clearance obtained & from which
                          authority (if applicable)*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="EnvoirmentClearence"
                          onChange={handleChange}
                          value={formData.EnvoirmentClearence}
                        />
                        {errors.EnvoirmentClearence && (
                          <span className="error">
                            {errors.EnvoirmentClearence}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="df-input-field">
                        <label for="address">
                          Indicate Industrial License No. & Date, Product &
                          Quantity licensed.*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="LicenseNumber"
                          onChange={handleChange}
                          value={formData.LicenseNumber}
                        />
                        {errors.LicenseNumber && (
                          <span className="error">{errors.LicenseNumber}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-12">
                      DETAILS OF STORES UNDER PRODUCTION OR DEVELOPMENT
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Present monthly productions*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="MonthlyProductions"
                          onChange={handleChange}
                          value={formData.MonthlyProductions}
                        />
                        {errors.MonthlyProductions && (
                          <span className="error">
                            {errors.MonthlyProductions}
                          </span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">Spare capacity available*</label>
                        <input
                          type="text"
                          id="address"
                          name="SpareCapacity"
                          onChange={handleChange}
                          value={formData.SpareCapacity}
                        />
                        {errors.SpareCapacity && (
                          <span className="error">{errors.SpareCapacity}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Future plan for development*
                        </label>
                        <input type="text" id="address" name="FuturePlan" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Basic research programme in hand*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="BasicReaserch"
                          onChange={handleChange}
                          value={formData.BasicReaserch}
                        />
                        {errors.BasicReaserch && (
                          <span className="error">{errors.BasicReaserch}</span>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address">
                          Has your product been tested by any agency?*
                        </label>
                        <input
                          type="file"
                          id="address"
                          name="productTested"
                          style={{ padding: "10px" }}
                          onChange={handleChange}
                          value={formData.productTested}
                        />
                        {errors.productTested && (
                          <span className="error">{errors.productTested}</span>
                        )}
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="df-input-field">
                        <label for="address" style={{ fontSize: "13px" }}>
                          Whether firm is ISO certified or having any other
                          certification?*
                        </label>
                        <input
                          type="text"
                          id="address"
                          name="ISEcertification"
                          onChange={handleChange}
                          value={formData.ISEcertification}
                        />
                        {errors.ISEcertification && (
                          <span className="error">
                            {errors.ISEcertification}
                          </span>
                        )}
                      </div>
                    </div>
                    {loading ? (
                      <div className="loader-container">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <div class="col-md-12">
                        <div class="df-booking__form-btn mt-10">
                          <button
                            type="submit"
                            class="primary-btn"
                            onClick={handleSubmit}
                          >
                            send now
                            <span class="icon__box">
                              <img
                                class="icon__first"
                                src="assets/img/icon/arrow-white.webp"
                                alt="image not found"
                              />
                              <img
                                class="icon__second"
                                src="assets/img/icon/arrow-white.webp"
                                alt="image not found"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default VendorRegistretion;
