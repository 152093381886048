import React, { useEffect, useState } from "react";
import Header from "../Header";
import Slider from "./Slider";
import HomeAbout from "./HomeAbout";
import HomeVenderList from "./HomeVenderList";
import HomeVendorPage from "./HomeVendorPage";
import HomeFrenchiiseSection from "./HomeFrenchiiseSection";
import HomeQestion from "./HomeQestion";
import HomeReadBlogPage from "./HomeReadBlogPage";
import Footer from "./Footer";
import Sloganpage from "./Sloganpage";
import Loader from "./Loader";
import Languagetranslator from "./Languagetranslator";

function Home() {
  const [showLoader, setShowLoader] = useState(true);
  const [showSlogan, setShowSlogan] = useState(false);

  useEffect(() => {
    const isVisited = localStorage.getItem("visitedHomePage");
    if (isVisited) {
      setShowLoader(false);
      setShowSlogan(false);
    } else {
      const loaderTimer = setTimeout(() => {
        setShowLoader(false);
        setShowSlogan(true);
        localStorage.setItem("visitedHomePage", "true");

        const hideSloganTimer = setTimeout(() => {
          setShowSlogan(false);
        }, 3000);
        return () => clearTimeout(hideSloganTimer);
      }, 3000);
      return () => clearTimeout(loaderTimer);
    }
  }, []);

  useEffect(() => {
    const clearLocalStorage = () => {
      localStorage.removeItem("visitedHomePage");
    };

    window.addEventListener("beforeunload", clearLocalStorage);

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage);
    };
  }, []);

  return (
    <>
      {showLoader && (
        <div>
          <Loader />
        </div>
      )}

      {!showLoader && !showSlogan && (
        <>
          {/* <div style={{ position: "relative" }}>
            <div
              style={{
                position: "fixed",
                zIndex: "999",
                bottom: "0",
                right: "0",
              }}
            >
              <Languagetranslator />
            </div>
          </div> */}
          <Header />
          <Slider />
          <HomeAbout />
          <div style={{ overflow: "hidden" }}>
            {" "}
            <HomeVenderList />
          </div>
          <HomeVendorPage />

          <div style={{ overflow: "hidden" }}>
            <HomeFrenchiiseSection />
          </div>
          <HomeQestion />
          <HomeReadBlogPage />
          <Footer />
        </>
      )}
      {showSlogan && <Sloganpage />}
    </>
  );
}

export default Home;
