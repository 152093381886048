import React from "react";
import Header from "../Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { t } = useTranslation();
  const BlogData = [
    {
      img: "assets/img/customblog/blog1custome.jpg",
      title: t("blogPageTitlebutton2"),
      description: t("blogPageDesc1"),
      date: "26-07-2024",
     url: "Kargil-Vijay-Diwas-2024", // URL slug without '/blogdetails'

    },

    {
      img: "assets/img/customblog/sanketkaleji.JPG",
      title: t("blogPageTitlebutton2"),
      description: t("blog2maintitle"),
      date: "26-07-2023",
      url: "/Celebrating-25th-Kargil-Vijay-Diwas", // URL for navigation

    },
    {
      img: "assets/img/newimages/img12.jpg",
      title: t("blogPageTitlebutton3"),
      description: t("blogPageDesc3"),
      date: "27-05-2023",
      url: "/blogdetails", // URL for navigation

    },
    {
      img: "assets/img/newimages/img11.jpg",
      title: t("blogPageTitlebutton4"),
      description: t("blogPageDesc4"),
      date: "28-02-2023",
      url: "/blogdetails", // URL for navigation

    },
    {
      img: "assets/img/newimages/img10.jpg",
      title: t("blogPageTitlebutton5"),
      description: t("blogPageDesc5"),
      date: "19-04-2023",
      url: "/blogdetails", // URL for navigation

    },
    {
      img: "assets/img/newimages/img9.jpg",
      title: t("blogPageTitlebutton6"),
      description: t("blogPageDesc6"),
      date: "27-11-2023",
      url: "blogdetails", // URL for navigation

    },
    {
      img: "assets/img/newimages/img8.jpg",
      title: t("blogPageTitlebutton7"),
      description: t("blogPageDesc7"),
      date: "15-08-2023",
      url: "blogdetails", // URL for navigation

    },
    {
      img: "assets/img/newimages/img7.jpg",
      title: t("blogPageTitlebutton8"),
      description: t("blogPageDesc8"),
      date: "20-06-2023",
      url: "blogdetails", // URL for navigation

    },
    {
      img: "assets/img/newimages/img6.jpg",
      title: t("blogPageTitlebutton9"),
      description: t("blogPageDesc9"),
      date: "01-05-2023",
      url: "blogdetails", // URL for navigation

    },
  ];
  return (
    <>
      <Header />
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("pageTitle5")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("pageTitle5")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="df-blog__area section-spacing p-relative fix">
          <div class="circle-2"></div>
          <div class="circle-3"></div>
          <div class="container">
            <div class="row g-5">
              {BlogData.map((i , index) => (
                <div class="col-xl-4 col-lg-6 col-md-6">
                  <div class="df-blog2__box bg-2">
                    <div class="df-blog2__thumb">
                      <Link  
                       
                        style={{ textDecoration: "none" }}
                        to={index === 0 
                          ? `/Kargil-Vijay-Diwas-2024` 
                          : index === 1 
                          ? `/Celebrating-25th-Kargil-Vijay-Diwas`
                          : `/blogdetails`
                      }
                      
                      >
                        <img src={i.img} alt="image not found"  style={{height:'350px'}} />
                      </Link>
                    </div>
                    <div class="df-blog2__content">
                      <div class="df-blog2__meta">
                        <a>
                          <span class="tag">{i.title}</span>
                        </a>
                        <span class="blog-date">{i.date}</span>
                      </div>

                      <h3 class="df-blog2__title">
                        <a
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            webkitLineClamp: "1",
                            webkitBoxOrient: "vertical",
                          }}
                        >
                          {i.description}
                        </a>

                        <a
                          className="blog_more_btn"
                          style={{ marginLeft: "10px" }}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to={index === 0 
                              ? `/Kargil-Vijay-Diwas-2024` 
                              : index === 1 
                              ? `/Celebrating-25th-Kargil-Vijay-Diwas`
                              : `/blogdetails`
                          } // Conditional URL based on index
                            >
                            <span class="tag"> {t("blogPageReadmorebut")}</span>
                          </Link>
                        </a>
                      </h3>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div
                class="df-blog2__area-btn text-center mt-60 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <Link to="/blogdetails" class="load-btn">
                  Load More<i class="fa-duotone fa-spinner"></i>
                </Link>
              </div> */}
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Blog;
