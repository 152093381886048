import React, { useEffect, useState } from "react";
import Header from "../Header";
import Footer from "../Pages/Footer";
import { auth } from "../Firebaseconfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
function AdminLogin() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('email', email, password);
      
        try {
          const userData = await signInWithEmailAndPassword(auth, email, password);
          console.log(userData);
          toast("Login successful!");
          localStorage.setItem("loginToken", 'loginToken321123456765');

          navigate('/Home')
        } catch (error) {
          const errorCode = error.code;
          const errorMessage = error.message;
          toast.error('Wrong Email and Password. Please try again.');
          console.error(`Error code: ${errorCode}, Message: ${errorMessage}`);
          
          
        }
      };
  
      useEffect(()=>{
      const loginToken  = localStorage.getItem('loginToken');
      if(loginToken){
        navigate('/home')
      }
      },[])


  return (
    <div>
      <Header />
      <main>
        <section
          class="df-sign-in__area section-spacing p-relative fix"
          style={{ paddingTop: "40px" }}
        >
          <div class="circle-2"></div>
          <div class="circle-3"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-6">
                <div
                  class="df-booking2__form wow fadeInUp"
                  data-wow-delay=".3s"
                >
                  <div class="section__title-wrapper text-center section-title-spacing">
                    <h3 class="section__title x-large">Wellcome Again</h3>
                    <p class="mt-15">
                      The faster you fill up. the faster you get a login
                    </p>
                  </div>
                  <form action="#">
                    <div class="row gx-5">
                      <div class="col-md-12">
                        <div class="df-input-field">
                          <input
                            type="text"
                            id="email3"
                            name="email3"
                            placeholder="Username or Email *"
                            value={email}
                            onChange={(e)=>setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="df-input-field mb-0">
                          <input
                            type="password"
                            name="password"
                            placeholder="Password *"
                            value={password}
                            onChange={(e)=>setPassword(e.target.value)}

                          />
                          <span class="fa fa-eye pass-icon"></span>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row align-items-center justify-content-between">
                          <div class="sign-meta">
                            <div class="df-login">
                              <input
                                type="checkbox"
                                value="lsRememberMe"
                                id="rememberMe"
                              />{" "}
                              <label for="rememberMe">Remember me</label>
                            </div>
                            <div class="edu-lost-password">
                              {/* <a class="btn-read-more" href="elements-reset-password.html"><span>Lost your
                                                    password?</span></a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="df-booking2__form-btn mt-15 mb-30">
                          <button
                            type="submit"
                            class="primary-btn sign-btn w-100"
                            onClick={handleSubmit}
                          >
                            login
                            <span class="icon__box">
                              <img
                                class="icon__first"
                                src="assets/img/icon/arrow-white.webp"
                                alt="image not found"
                              />
                              <img
                                class="icon__second"
                                src="assets/img/icon/arrow-white.webp"
                                alt="image not found"
                              />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default AdminLogin;
