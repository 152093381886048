import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Protectted(props) {
    const { Component } = props;
    const navigate = useNavigate();

    useEffect(() => {
        let login = localStorage.getItem('loginToken');
        if (!login) {
            navigate('/');
        }
    }, []); // Empty dependency array to run the effect only once on component mount

    return (
        <div>
            <Component />
        </div>
    );
}

export default Protectted;
