import React, { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { Link } from "react-router-dom";
import Languagetranslator from "./Pages/Languagetranslator";
import { useTranslation } from "react-i18next";
function Header() {
  const { t } = useTranslation();
  const [sidebar, setSidebar] = useState(false);

  const HandelSidebar = () => {
    setSidebar(!sidebar);
  };

  console.log(sidebar);

  return (
    <>
      <header
        style={{
          position: "sticky",
          top: "0",
          // backgroundColor: "#ffffff",
          zIndex: "99",
        }}
      >
        <div class="df-header4 bg-white">
          <div id="header-sticky">
            <div class="container">
              <div class="mega-menu-wrapper p-relative">
                <div class="d-flex align-items-center justify-content-between" style={{height:"115px"}}>
                  <div class="header__logo-4">
                    <Link to="/" class="logo-dark">
                      <img src="assets/img/logo/new_logo1.png" alt="logo-img" />
                    </Link>
                  </div>
                  <div class="main-menu main-menu1 d-none d-md-block">
                    <nav id="mobile-menu">
                      <ul className="menusssist">
                        <li class="menu-item-has-children">
                          <Link to="/">{t("headerlist1")}</Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link to="/about">{t("headerlist2")}</Link>
                        </li>
                        <li class="menu-item-has-children">
                          <a>{t("headerlist3")}</a>
                          <ul class="sub-menu">
                            <li>
                              <Link to="/vendor">{t("headerlist3")}</Link>
                            </li>
                            <li>
                              <Link to="/vendorregestration">
                                {t("headerlist3A")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                        <li class="has-dropdown has-mega-menu">
                          <Link to="/OurFrenchice">{t("headerlist4")}</Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link to="/bscmegamart">{t("headerlist5")}</Link>
                        </li>
                        <li class="menu-item-has-children">
                          <Link to="/blog">{t("headerlist6")}</Link>
                        </li>
                        <li>
                          <Link to="/gallery">{t("headerlistgallery")}</Link>
                        </li>
                        <li>
                          <Link to="/contact">{t("headerlist7")}</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="df-header4-bottom-right d-flex justify-content-end align-items-center">
                    <div class="df-header4-meta-items-3 d-flex align-items-center">
                      <div class="df-header4-meta-item df-header4-menu-meta align-items-center">
                        <div class="df-header4-meta-icon-3 df-header4-cart">
                          <div class="df-header4-cart-wrapper">
                            <div class="df-header4-cart-item">
                              <ul>
                                <li>
                                  <div class="df-header4-cart-content">
                                    <div class="df-header4-cart-thumb">
                                      <a href="#">
                                        {" "}
                                        <img
                                          src="assets/img/shop/product-13.webp"
                                          alt="img not found!"
                                        />
                                      </a>
                                    </div>
                                    <div class="df-header4-cart-title-wrapper">
                                      <h4 class="df-header4-cart-title">
                                        <a href="#">DF Water Filter</a>
                                      </h4>
                                      <span>$59.00</span>
                                    </div>
                                  </div>
                                  <div class="df-header4-cart-remove text-end">
                                    <a href="#">
                                      {" "}
                                      <i class="fa-solid fa-xmark"></i>
                                    </a>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div class="df-header4-cart-total">
                              <p>Total:</p>
                              <span>$59.00</span>
                            </div>
                            <div class="df-header4-cart-footer">
                              <a href="#" class="primary-btn">
                                Checkout
                              </a>
                              <a href="#" class="primary-btn bordered">
                                View Cart
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*  */}

                    {/* <Languagetranslator /> */}
                    <div className="buttonsApply">
                      <div className="d-flex gap-2 justify-content-between">
                        <Link to="/vendorregestration">
                          <button className="primary-btn fsize14 lang_btn">
                            {t("headerButven")}
                          </button>
                        </Link>
                        <Link to="/OurFrenchice">
                          <button className="primary-btn fsize14 lang_btn">
                            {t("headerButtonfranchise")}
                          </button>
                        </Link>
                      </div>
                    </div>

                    <div className="mobhamberger">
                      {" "}
                      <button
                        style={{ backgroundColor: "transparent" }}
                        class="side-toggle primary-btn apply_franch ml-25"
                        onClick={HandelSidebar}
                      >
                        <button class="side-toggle">
                          <span class="menu__bar">
                            <span class="bar-icon">
                              <span></span>
                              <span></span>
                              <span></span>
                            </span>
                          </span>
                        </button>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {sidebar && (
        <div>
          <aside style={{ position: "absolute", top: "47px" }}>
            <div class="side-info" style={{ position: "fixed" }}>
              <div class="side-info-content">
                <div class="offset__widget offset__header">
                  <div class="offset__logo">
                    <Link to="/" style={{ mixBlendMode: "difference" }}>
                      <div style={{}}>
                        <img
                          style={{ maxWidth: "70px" }}
                          src="assets/img/logo/menulogpng2.png"
                          alt="logo"
                        />
                      </div>
                    </Link>
                  </div>
                  <button class="side-info-close">
                    <IoIosClose onClick={HandelSidebar} />
                  </button>
                </div>
                <div class="offset__widget offset__support d-none d-sm-block">
                  <Link to="/vendorregestration">
                    <h3 class="offset__title">{t("pageTitle7")}</h3>
                  </Link>
                </div>
                <div class="mobile-menu d-xl-none fix"></div>
                <div
                  class="offset__widget offset__support"
                  style={{ marginTop: "-30px" }}
                >
                  <ul className="menus">
                    <li>
                      {" "}
                      <Link to="/vendorregestration">
                        <button className="primary-btn fsize14 lang_btn">
                          {t("headerButven")}
                        </button>
                      </Link>
                    </li>
                    <li>
                      {" "}
                      <Link to="/OurFrenchice">
                        <button className="primary-btn fsize14 lang_btn">
                          {t("headerButtonfranchise")}
                        </button>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">{t("pageTitle")}</Link>
                    </li>
                    <li>
                      <Link to="/about">{t("pageTitle1")}</Link>
                    </li>
                    <li>
                      <Link to="/vendor">{t("pageTitle2")}</Link>
                    </li>
                    <li>
                      <Link to="/vendorregestration">{t("pageTitle9")}</Link>
                    </li>
                    <li>
                      <Link to="/OurFrenchice">{t("pageTitle3")}</Link>
                    </li>
                    <li>
                      <Link to="/bscmegamart">{t("pageTitle4")}</Link>
                    </li>
                    <li>
                      <Link to="/blog"> {t("pageTitle5")}</Link>
                    </li>

                    <li>
                      <Link to="/contact">{t("pageTitle6")}</Link>
                    </li>
                    <li>
                    <Link to="/gallery">{t("headerlistgallery")}</Link>
                    </li>

                  </ul>

                  {/* <div class=" " style={{ marginTop: "40px" }}>
                    <>
                      <h3 class="" style={{ fontSize: "20px", color: "#fff" }}>
                        {t("pageTitle10")}
                      </h3>
                    </>
                  </div> */}

                  {/* <div class="meta-item mt-20 mb-20">
                    <div class="meta-item__icon-2">
                      <i class="icon-007-telephone"></i>
                    </div>
                    <div class="meta-item__text-2">
                      <span>
                        <a href="tel:+91 9325502675">+91 9325502675</a>
                      </span>
                    </div>
                  </div> */}
                  {/* <div class="meta-item mb-20">
                    <div class="meta-item__icon-2 style-2">
                      <i class="icon-052-email"></i>
                    </div>
                    <div class="meta-item__text-2">
                      <span>
                        <a href="mailto:bhutpurvsainikcanteen@gmail.com">
                          <span class="__cf_email__">
                            bhutpurvsainikcanteen@gmail.com{" "}
                          </span>
                        </a>
                      </span>
                    </div>
                  </div> */}
                  {/* <div class="meta-item">
                    <div class="meta-item__icon-2 style-3">
                      <i class="icon-030-pin"></i>
                    </div>
                    <div class="meta-item__text-2">
                      <span>
                        <a href="https://www.google.com/maps?q=Opposite+to+Shinde+Hospital,+Gurunagar,+bhadrawati+442902">
                          {t("footerAdd1")}
                          <br /> {t("footerAdd2")}
                        </a>
                      </span>
                    </div>
                  </div> */}
                </div>
                <div class="offset__widget offset__gallery">
                  <div class="offset__instagram d-none d-sm-block">
                    <h6
                      style={{ fontSize: "20px", color: "#fff" }}
                      class="offset__sub-title small fw-400 mb-30"
                    >
                      {t("pageTitle11")}
                    </h6>
                    <div class="tp-insta">
                      <div class="row">
                        <div class="col-4 col-sm-4">
                          <div class="offset-insta__thumb">
                            <a href="#">
                              <img
                                src="assets/img/mart1.jpeg"
                                alt="image not found"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-4 col-sm-4">
                          <div class="offset-insta__thumb">
                            <a href="#">
                              <img
                                src="assets/img/fra1.jpeg"
                                alt="image not found"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-4 col-sm-4">
                          <div class="offset-insta__thumb">
                            <a href="#">
                              <img
                                src="assets/img/mart5.jpeg"
                                alt="image not found"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-4 col-sm-4">
                          <div class="offset-insta__thumb">
                            <a href="#">
                              <img
                                src="assets/img/mart8.jpeg"
                                alt="image not found"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-4 col-sm-4">
                          <div class="offset-insta__thumb">
                            <a href="#">
                              <img
                                src="assets/img/fra3.jpeg"
                                alt="image not found"
                              />
                            </a>
                          </div>
                        </div>
                        <div class="col-4 col-sm-4">
                          <div class="offset-insta__thumb">
                            <a href="#">
                              <img
                                src="assets/img/fra9.jpeg"
                                alt="image not found"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </aside>
        </div>
      )}
    </>
  );
}

export default Header;
