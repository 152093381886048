import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaSearchLocation } from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SiGoogleforms } from "react-icons/si";
import { CiCircleList } from "react-icons/ci";
import { IoIosSettings } from "react-icons/io";
import { CiLogout } from "react-icons/ci";
import { GrUserAdmin } from "react-icons/gr";

function Sidebar() {
  const location = useLocation();
 const navigate = useNavigate();
  const handleLogout = () => {
    navigate('/');
    window.localStorage.clear();
  }

  return (
    <div className="sidebar">
      <div className="logo-details">
        <i className="bx bxl-c-plus-plus"></i>
        <GrUserAdmin />
        <span className="logo_name mx-3" style={{ fontSize: "15px" }}>
          Admin
        </span>
      </div>
      <ul className="nav-links">
        <li>
          <Link to="/home"  className={location.pathname === "/home" ? "active" : ""}>
            <RxDashboard className="mx-3" />
            <span className="links_name mx-3">Dashboard</span>
          </Link>
        </li>
        <li >
          <Link to="/formList" className={location.pathname === "/formList" ? "active" : ""}>
            <SiGoogleforms className="mx-3" />
            <span className="links_name mx-3">Registration forms</span>
          </Link>
        </li>
        <li >
          <Link to="/enquiry" className={location.pathname === "/enquiry" ? "active" : ""}>
            <CiCircleList className="mx-3" />
            <span className="links_name mx-3">Total Enquiry</span>
          </Link>
        </li>
        <li >
          <Link to="/franchic" className={location.pathname === "/franchic" ? "active" : ""}>
            <SiGoogleforms className="mx-3" />
            <span className="links_name mx-3">Franchise forms</span>
          </Link>
        </li>
        <li>
          <a href="#">
            <IoIosSettings className="mx-3" />
            <span className="links_name mx-3">Setting</span>
          </a>
        </li>
        <li>
          <a onClick={handleLogout}>
            <CiLogout className="mx-3" />
            <span className="links_name mx-3">Logout</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
