import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function HomeQestion() {
  const { t } = useTranslation();
  return (
    <div>
      <section class="df-questions__area section-spacing">
        <div class="container">
          <div
            class="df-questions__area-inner wow fadeInUp"
            data-wow-delay=".3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div class="df-questions__content">
              <div class="section__title-wrapper">
                <span class="section__subtitle bg-lighter">
                  {t("homeQuestionSubTitle")}
                </span>
                <h2 class="section__title">{t("homeQuestionTitle")} </h2>
              </div>
              <p class="mt-40 mb-40">{t("homeQuestionDesc")}</p>
              <div
                class="df-testimonial__button"
                style={{
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Link to="/contact" class="primary-btn">
                  {t("homeQuestionDescButton1A")}
                  <span class="icon__box">
                    <img
                      class="icon__first"
                      src="assets/img/icon/arrow-white.webp"
                      alt="image not found"
                    />
                    <img
                      class="icon__second"
                      src="assets/img/icon/arrow-white.webp"
                      alt="image not found"
                    />
                  </span>
                </Link>
                <a
                  href="https://wa.me/9325502675"
                  class="primary-btn"
                  style={{ marginLeft: "0px", margin: "20px 0" }}
                >
                  {t("homeQuestionDescButton1B")}
                  <span class="icon__box">
                    <img
                      class="icon__first"
                      src="assets/img/icon/arrow-white.webp"
                      alt="image not found"
                    />
                    <img
                      class="icon__second"
                      src="assets/img/icon/arrow-white.webp"
                      alt="image not found"
                    />
                  </span>
                </a>
              </div>
            </div>
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    {t("homeQuestionMain1")}
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">{t("homeQuestionAns1")}</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    {t("homeQuestionMain2")}
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">{t("homeQuestionAns2")}</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    {t("homeQuestionMain3")}
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">{t("homeQuestionAns3")}</div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    {t("homeQuestionMain4")}
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">{t("homeQuestionAns4")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomeQestion;
