// FirebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, set } from 'firebase/database';
import { getStorage } from 'firebase/storage'; 
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAR0rhJW1DbLWflivpbyS1pR5lDNVovri8",
  authDomain: "testusers-26b2b.firebaseapp.com",
  projectId: "testusers-26b2b",
  storageBucket: "testusers-26b2b.appspot.com",
  messagingSenderId: "506671766463",
  appId: "1:506671766463:web:c2ad15495dd68469205f0a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);
const auth = getAuth(app);
const setDatabase = set;

export { database, storage, auth, ref, onValue, setDatabase };
