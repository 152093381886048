import React from "react";
import Header from "../Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Termsandcondition = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />{" "}
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("terConPageTitle")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("headerlist1")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("terConPageTitle")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-policy__area section-spacing">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-12">
                <div class="df-policy__wrapper df-policy__translate p-relative z-index-1">
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("terConTitle1")}</h3>
                    <p>{t("terConDesc1")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("terConTitle2")}</h3>
                    <p>{t("terConDesc2")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("terConTitle3")}</h3>
                    <p>{t("terConDesc3")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("terConTitle4")}</h3>
                    <p>{t("terConDesc4")}</p>
                  </div>
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("terConTitle5")}</h3>
                    <p>{t("terConDesc5")}</p>
                  </div>
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("terConTitle6")}</h3>
                    <p>{t("terConDesc6")}</p>
                  </div>
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("terConTitle7")}</h3>
                    <p>{t("terConDesc7")}</p>
                    <ul>
                      <li>{t("terConDesc7A")}</li>
                      <li>{t("terConDesc7B")}</li>
                      <li>{t("terConDesc7C")}</li>
                      <li>{t("terConDesc7D")}</li>
                      <li>{t("terConDesc7E")}</li>
                      <li>{t("terConDesc7F")}</li>
                    </ul>
                  </div>
                  <div class="df-policy__contact">
                    <h3 class="df-policy__title df-policy__title-2">
                      {t("terConTitle8")}
                    </h3>
                    <p>{t("terConDesc8")}</p>

                    <ul>
                      <li>
                        {t("terConDesc8A")}
                        <span>
                          <a href="mailto:contact@bhutpurvsainikcanteen.com">
                            <span class="__cf_email__">
                              contact@bhutpurvsainikcanteen.com
                            </span>
                          </a>
                        </span>
                      </li>
                    </ul>

                    <div class="df-policy__address">
                      <p class="mb-0">
                        <a
                          href="https://www.google.com/maps?q=Opposite+to+Shinde+Hospital,+Gurunagar,+bhadrawati+442902"
                          target="_blank"
                        >
                          {t("terConDesc7B")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Termsandcondition;
