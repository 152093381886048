import React, { useState, useEffect } from "react";

const Sloganpage = () => {
  const [fontSize, setFontSize] = useState('29px');

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 600) {
        setFontSize('13px');
      } else {
        setFontSize('29px');
      }
    };

    handleResize(); // Set the initial size
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div>
      <div style={{ position: "relative" }}>
        <div
          data-aos="zoom-in-up"
          style={{
            backgroundImage: "url(assets/img/super.avif)",
            backgroundPosition: "center",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#fff",
            textAlign: "center",
            zIndex: "999",
            height: "100vh",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "#000",
              position: "relative",
              height: "100vh",
              opacity: "0.7",
            }}
          ></div>
          <div
            className="textbackgroundheight"
            style={{
              opacity: "0.5",
              width: "65%",
              borderRadius: "10px",
              padding: "30px",
              top: "50%",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundImage: "linear-gradient(to right,#007bba, #2dc4fd)",
              color: "#fff",
            }}
          ></div>
          <b
            className="slogan"
            style={{
              width: "65%",
              top: "53%",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            भूतपूर्व सैनिक कैंटीन <br />
            <span
              style={{
                position: 'relative',
                bottom: '29px',
                fontSize: fontSize,
                display: 'inline-block',
                textWrap:'nowrap'
              }}
            >
              सैनिक परिवार एवं आम जनता के लिए
            </span>
          </b>
        </div>
      </div>
    </div>
  );
};

export default Sloganpage;
