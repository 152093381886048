import Header from "./Componets/Header";
import PublicRoutes from "./Componets/Routes/PublicRoutes";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  return (
    <div >
 <ToastContainer/>
   <PublicRoutes/>
    </div>
  );
}

export default App;
