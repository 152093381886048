import React from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", lang: "English" },
  { code: "hi", lang: "Hindi" },
];

const Languagetranslator = () => {
  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div>
      <>
        <div className="btn-container">
          <select className="primary-btn lang_btn" onChange={(e) => changeLanguage(e.target.value)}>
            {languages.map((lng) => (
              <option key={lng.code} value={lng.code}>
                {lng.lang}
              </option>
            ))}
          </select>
        </div>
      </>
    </div>
  );
};

export default Languagetranslator;
