import React from "react";
import Header from "../Header";
import Footer from "../Pages/Footer";
import { useLocation, useParams } from "react-router-dom";

function VendorProfile() {
  const location = useLocation();

  const user = location.state;

  console.log(user);
  return (
    <div>
      <Header />
      { user &&
      <main>
        {/* <div class="adjust-header-space bg-common-white"></div> */}

    

        <section class="" style={{paddingTop:"30px"}}>
          <div class="container">
            <div class="row g-50 align-items-center">
              <div class="col-lg-6 order-lg-0 order-1">
                <div class="df-service5__thumb">
                  <img
                    src="assets/img/portfolio/masonry/img-01.webp"
                    alt="img not found"
                  />
                </div>
              </div>
              <div class="col-lg-6 order-lg-1 order-0">
                <div class="df-service5__content">
                  <div class="section__title-wrapper">
                    <span class="section__subtitle bg-lighter">
                      ABOUT Vendor
                    </span>
                    <h2 class="section__title">{user.firmName}</h2>
                  </div>
                  <p class="mt-35 mb-35">{user.firmDescription}.</p>
                  <div class="df-service5__info">
                    <div class="df-service5__client-info">
                      <h4 class="df-service5__client-title mb-5">
                        Branch Office
                      </h4>
                      <p class="df-service5__client-name">
                        {user.BranchOffice}
                      </p>
                    </div>
                    <div class="df-service5__client-category">
                      <h4 class="df-service5__client-title mb-5">
                        Head Office
                      </h4>
                      <p>{user.HeadOffice}</p>
                    </div>
                    <div class="df-service5__meta-info">
                      <h4 class="df-service5__meta-title mb-5">Factory</h4>
                      <p class="df-service5__meta-day">{user.Factory}</p>
                    </div>
                    <div class="df-service5__meta-address">
                      <h4 class="df-service5__meta-title mb-5">
                        Registration-date
                      </h4>
                      <p>{user.DateOfRegistretion}</p>
                    </div>
                  </div>
                  <div class="df-service5__info" style={{ marginTop: "30px" }}>
                    <div class="df-service5__client-info">
                      <h4 class="df-service5__client-title mb-5">
                        Mobile Number
                      </h4>
                      <p class="df-service5__client-name">
                        {user.OfficeNumber}
                      </p>
                    </div>
                    <div class="df-service5__client-category">
                      <h4 class="df-service5__client-title mb-5">
                        Office LandLine
                      </h4>
                      <p>{user.OfficeLandLine}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-challenge__area pb-20">
          <div class="container">
            <div class="row section-title-spacing">
              <div class="col-lg-4">
                <div class="section__title-wrapper">
                  <span class="section__subtitle bg-lighter"></span>
                  <h2 class="section__title">Certificates</h2>
                </div>
              </div>
              <div class="col-lg-8 d-flex justify-content-end">
                <div class="df-challenge__content">
                  <p class="mb-40 mt-40">
                    The first step is to create a plumbing plan that outlines
                    the placement of fixtures, pipes, drains, and other
                    components. Consider factors such as the layout of the
                    space, the number of fixtures, and the flow of water
                    professional plumbers work.
                  </p>
                  <div class="df-challenge__feature-list">
                    <ul>
                      <li>
                        <span class="list-icon">
                          <i class="icon-058-check"></i>
                        </span>
                        <a href={user.ISEcertification} download="ISE_certification.pdf">
  <p>ISO certification</p>
</a>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="icon-058-check"></i>
                        </span><a href={user.BasicReaserch} download="ncome_Tax_clearance.pdf">
                        <p>Income Tax clearance Certificate</p>
                        </a>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="icon-058-check"></i>
                        </span>
                        <a href={user.TexRegistration} download="ISE_certification.pdf">
  <p>  CST Certificates</p>
</a>
                      </li>
                      <li>
                        <span class="list-icon">
                          <i class="icon-058-check"></i>
                        </span>
                        <p> tested by agency</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-service5__area section-spacing">
          <div class="container">
            <div class="row g-50 align-items-center">
              <div class="col-lg-6 order-lg-1 order-0">
                <div class="df-service5__content">
                  <div class="section__title-wrapper">
                    <span class="section__subtitle bg-lighter">
                      DETAILS OF MANAGERIAL & TECHNICAL PERSONNEL
                    </span>
                    <h2 class="section__title">Total no. of employees</h2>
                  </div>
                  <p class="mt-35 mb-35">{user.numberofemployess}.</p>
                  <div class="df-service5__info">
                    <div class="df-service5__client-info">
                      <h4 class="df-service5__client-title mb-5">
                        The min. requirements, experience & qualification
                      </h4>
                      <p class="df-service5__client-name">
                        {user.minimmumrequreiment}
                      </p>
                    </div>
                    <div class="df-service5__client-category">
                      <h4 class="df-service5__client-title mb-5">
                        foreigner Staff
                      </h4>
                      <p>{user.foreingerStaff}</p>
                    </div>
                    <div class="df-service5__meta-info">
                      <h4 class="df-service5__meta-title mb-5">
                        Training Program of Staff
                      </h4>
                      <p class="df-service5__meta-day">
                        {user.trainingPrograme}
                      </p>
                    </div>
                    <div class="df-service5__meta-address">
                      <h4 class="df-service5__meta-title mb-5">
                        RegistrationNdate
                      </h4>
                      <p>{user.RegistrationNdate}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 order-lg-1 order-0">
                <div class="df-service5__content">
                  <div class="section__title-wrapper">
                    <span class="section__subtitle bg-lighter">
                      TYPE OF INDUSTRY
                    </span>
                    <h4 class="section__title">
                      SMALL / MEDIUM / LARGE SCALE INDUSTRY
                    </h4>
                  </div>
                  <p class="mt-35 mb-35">.</p>
                  <div class="df-service5__info">
                    <div class="df-service5__client-info">
                      <h4 class="df-service5__client-title mb-5">
                        Factroy Number
                      </h4>
                      <p class="df-service5__client-name">
                        {user.FactroyNumber}
                      </p>
                    </div>
                    <div class="df-service5__client-category">
                      <h4 class="df-service5__client-title mb-5">
                        Yeasr of commencment
                      </h4>
                      <p>{user.YeasrOFCommencment}</p>
                    </div>
                    <div class="df-service5__meta-info">
                      <h4 class="df-service5__meta-title mb-5">
                        License Number
                      </h4>
                      <p class="df-service5__meta-day">{user.LicenseNumber}</p>
                    </div>
                    <div class="df-service5__meta-address">
                      <h4 class="df-service5__meta-title mb-5">
                        Annual Turnover for last 3 years
                      </h4>
                      <p>{user.AnnualTonOver}</p>
                    </div>
                  </div>
                  <div class="df-service5__info" style={{ marginTop: "30px" }}>
                    <div class="df-service5__client-info">
                      <h4 class="df-service5__client-title mb-5">
                        monthly productions
                      </h4>
                      <p class="df-service5__client-name">
                        {user.MonthlyProductions}
                      </p>
                    </div>
                    <div class="df-service5__client-category">
                      <h4 class="df-service5__client-title mb-5">
                        SpareCapacity
                      </h4>
                      <p>{user.SpareCapacity}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
}
      <Footer />
    </div>
  );
}

export default VendorProfile;
