

import React from "react";
import { useTranslation } from "react-i18next";
function HomeAbout() {
  const { t } = useTranslation();
  return (
    <>
      <section class="df-about4__area section-spacing">
        <div class="container">
          <div
            class="row g-5 gy-50 align-items-center wow fadeInUp"
            style={{
              visibility: "visible",
         
              animationName: "fadeInUp",
            }}
          >
            <div class="col-lg-6 order-lg-0 order-1">
              <div class="df-about4__thumb-wrapper">
                <div
                  // data-aos="zoom-in-right"
                  class=" d-none d-sm-block"
                >
                  <img
                    className="webImg"
                    src="assets/img/gallery/gallery/g66.jpg"
                    alt="img not found"
                  />
                </div>
                <div 
                 data-aos="zoom-in-left"
                class="df-about4__thumb2" style={{right:'-30px'}}>
                  <img
                    src="assets/img/newimages/img16.jpg"
                    alt="img not found"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 order-lg-1 order-0">
              <div class="df-about4__content">
                <div class="section__title-wrapper">
                  <span class="section__subtitle bg-lighter">
                    {t("homeAboutSubTitle")}
                  </span>
                  <h2 class="section__title">{t("homeAboutTitle1")}</h2>
                </div>
                <p class="mt-35 mb-35">
                  {t("homeAboutDesc1")}
                  <br /> <br />
                  {t("homeAboutDesc2")}
                  <br /> {t("homeAboutDesc3")}
                </p>
                {/* <div class="df-about4__button-wrapper">
                  <a href="https://wa.me/1234567890" class="primary-btn">
                    Know More
                    <span class="icon__box">
                      <img
                        class="icon__first"
                        src="assets/img/icon/arrow-white.webp"
                        alt="image not found"
                      />
                      <img
                        class="icon__second"
                        src="assets/img/icon/arrow-white.webp"
                        alt="image not found"
                      />
                    </span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default HomeAbout;
