import React from "react";
import PropTypes from 'prop-types';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";

function Slider({ interval }) {
  const { t } = useTranslation();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const slides = [
    {
      title: t("slidertitle1"),
      description: t("sliderdesc1"),
      image: "assets/img/banner_0.png",
    },
    {
      title: t("slidertitle2"),
      description: t("sliderdesc2"),
      image: "assets/img/banner_2.png",
    },
    {
      title: t("slidertitle3"),
      description: t("sliderdesc3"),
      image: "assets/img/banner_3.png",
    },
  ];

  return (
    <Carousel
      responsive={responsive}
      autoPlay={true}
      autoPlaySpeed={4000}
      infinite={true}
    >
      {slides.map((slide, index) => (
        <div key={index}>
          <div className="react-multi-carousel-item gradient-background" >
            <div className="container">
              <div className="row g-50 align-items-center justify-content-between">
                <div
                  data-aos="fade-right"
                  className="col-xl-5 col-lg-6 col-md-5"
                >
                  <div className="hero4__wrapper">
                    <h1
                      className="hero4__title wow fadeInUp"
                      data-wow-delay=".5s"
                    >
                      {slide.title}
                    </h1>
                    <p
                      className="hero4__price-tag wow fadeInUp"
                      data-wow-delay=".7s"
                    >
                      {slide.description}
                    </p>
                  </div>
                </div>
                <div
                  data-aos="fade-left"
                  className="col-xl-7 col-lg-6 col-md-7"
                >
                  <div
                    className="hero4__thumb-wrapper p-relative wow fadeInUp"
                    data-wow-delay=".5s"
                  >
                    <div className="hero4__thumb p-relative">
                      <img src={slide.image} alt={`image-${index}`} />
                      <img
                        src="assets/img/ecommerce/hero-mask.webp"
                        alt="img not found!"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
}

Slider.propTypes = {
  interval: PropTypes.number
};

Slider.defaultProps = {
  interval: 5000
};

export default Slider;
