


import React, { useState } from 'react';
import Header from '../Header';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Gallery() {
    const { t } = useTranslation();
    const [visibleImages, setVisibleImages] = useState(9); 

    const images = [
        { id: 1, src: "assets/img/gallery/gallery/g33.jpg" },
        { id: 2, src: "assets/img/gallery/gallery/g34.jpg" },
        // { id: 3, src: "assets/img/gallery/gallery/g35a.jpg" },
        { id: 4, src: "assets/img/gallery/gallery/g36.jpg" },
        { id: 5, src: "assets/img/gallery/gallery/g37.jpg" },
        // { id: 6, src: "assets/img/gallery/gallery/g37a.jpg" },
        { id: 7, src: "assets/img/gallery/gallery/g39.jpg" },
        { id: 8, src: "assets/img/gallery/gallery/g40.jpg" },
        { id: 10, src: "assets/img/gallery/gallery/g42a.jpg" },
        { id: 11, src: "assets/img/gallery/gallery/g43a.jpg" },
        { id: 12, src: "assets/img/gallery/gallery/g44.jpg" },
        { id: 13, src: "assets/img/gallery/gallery/g45.jpg" },
        { id: 14, src: "assets/img/gallery/gallery/g46a.jpg" },
        { id: 15, src: "assets/img/gallery/gallery/g47.jpg" },
        { id: 16, src: "assets/img/gallery/gallery/g48.jpg" },
        { id: 17, src: "assets/img/gallery/gallery/g45.jpg" },
        // { id: 18, src: "assets/img/gallery/gallery/g50.jpg" },
        { id: 19, src: "assets/img/gallery/gallery/g53.jpg" },
        { id: 20, src: "assets/img/gallery/gallery/g54.jpg" },
        { id: 21, src: "assets/img/gallery/gallery/g55.jpg" },
        { id: 22, src: "assets/img/gallery/gallery/g56.jpg" },
        // { id: 23, src: "assets/img/gallery/gallery/g57.jpg" },
        { id: 24, src: "assets/img/gallery/gallery/g58.jpg" },
        // { id: 25, src: "assets/img/gallery/gallery/g59.jpg" },
        { id: 26, src: "assets/img/gallery/gallery/g60.jpg" },
        { id: 27, src: "assets/img/gallery/gallery/g61.jpg" },
        { id: 28, src: "assets/img/gallery/gallery/g62.jpg" },
        { id: 29, src: "assets/img/gallery/gallery/g63.jpg" },
        // { id: 30, src: "assets/img/gallery/gallery/g64.jpg" },
        { id: 31, src: "assets/img/gallery/gallery/g65.jpg" },
        { id: 32, src: "assets/img/gallery/gallery/g66.jpg" },
        { id: 33, src: "assets/img/gallery/gallery/g67.jpg" },
        // { id: 34, src: "assets/img/gallery/gallery/g68.jpg" },
        { id: 35, src: "assets/img/gallery/gallery/g69.jpg" },
        { id: 36, src: "assets/img/gallery/gallery/g70.jpg" },
        { id: 37, src: "assets/img/gallery/gallery/g71.jpg" },
        { id: 38, src: "assets/img/gallery/gallery/g72.jpg" },
        { id: 39, src: "assets/img/gallery/gallery/g73.jpg" },
        { id: 40, src: "assets/img/gallery/gallery/g74.jpg" },
        { id: 41, src: "assets/img/gallery/gallery/g75.jpg" },
        { id: 42, src: "assets/img/gallery/gallery/g76.jpg" },
        { id: 43, src: "assets/img/gallery/gallery/g77.jpg" },
        { id: 44, src: "assets/img/gallery/gallery/g78.jpg" },
        { id: 45, src: "assets/img/gallery/gallery/g79.jpg" },
        // { id: 46, src: "assets/img/gallery/gallery/g80.jpg" },
        { id: 48, src: "assets/img/gallery/gallery/g82.jpg" },
        { id: 49, src: "assets/img/gallery/gallery/g83.jpg" },
        { id: 50, src: "assets/img/gallery/gallery/g84.jpg" }
    ];
    

    const handleLoadMore = (event) => {
        event.preventDefault();
    
        const nextVisibleImages = visibleImages + 9;
        setVisibleImages(nextVisibleImages > images.length ? images.length : nextVisibleImages);
    };
    

    return (
        <>
            <Header />
            <main>
            <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
<div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("headerlistgallery")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("headerlistgallery")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
</section>
                <section className="df-portfolio__area section-spacing x-clip p-relative bg-theme-4 b-top b-bottom-2">
                    <div className="area-shapes">
                        {/* Area shapes */}
                    </div>
                    <div className="container">
                        <div className="row g-5 row-cols-xl-3 row-cols-md-2 row-cols-1 wow fadeInUp">
                            {images.slice(0, visibleImages).map((image) => (
                                <div key={image.id} className="col">
                                    <div className="df-portfolio__item-box">
                                        <div className="df-portfolio__item-thumb ">
                                            <a href="" >
                                                <img  className='gallery_img' src={image.src} alt="image not found" />
                                            </a>
                                        </div>
                                        <div className="df-portfolio__item-content">
                                            <div className="df-portfolio__item-info">
                                                <h4 className="df-portfolio__item-title">
                                                    {/* <a href="">{image.title}</a> */}
                                                </h4>
                                                <a href="">
                                                    {/* <span className="tag">{image.tag}</span> */}
                                                </a>
                                            </div>
                                            <div className="df-portfolio__item-btn">
                                                <a href="" className="circle-btn is-white">
                                                    <span className="icon__box">
                                                        <img className="icon__first" src="assets/img/icon/arrow-theme.webp" alt="image not found" />
                                                        <img className="icon__second" src="assets/img/icon/arrow-theme.webp" alt="image not found" />
                                                    </span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {visibleImages < images.length && (
                            <div className="df-portfolio__area-btn text-center mt-60 wow fadeInUp" data-wow-delay=".3s">
<button className="load-btn" onClick={(e) => handleLoadMore(e)}>Load More <i className="fa-duotone fa-spinner"></i></button>
                            </div>
                        )}
                    </div>
                </section>
            </main>
        </>
    );
}

export default Gallery;



