import React from "react";

const Loader = () => {
  return (
    <div
      data-aos="zoom-in"
      style={{
        backgroundColor: "#fff",
        zIndex: "1000",
        position: "relative",
        // backgroundImage: "url(assets/img/background.jpg)",
        height: "100vh",
      }}
    >
      {/* <div
        className="loader-container"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
       
        <div class="loader-containersss">
          <div class="loader-barsss"></div>
        </div>
        <img
          className="loader-image"
          src="assets/img/logo/new_logo.png"
          alt="Loader"
        />

       
      </div> */}
      <div
        className=""
        style={{
          width: "90%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <img style={{ width: "100%" }} src="assets/img/logo/new_logo.png" />
      </div>
    </div>
  );
};

export default Loader;
