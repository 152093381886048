import React from "react";
import Footer from "./Footer";
import Header from "../Header";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Privacypolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />{" "}
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("priPolPageTitle")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("headerlist1")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span> {t("priPolPageTitle")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-policy__area section-spacing">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xl-12">
                <div class="df-policy__wrapper df-policy__translate p-relative z-index-1">
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("priPolTitle1")}</h3>
                    <p>{t("priPolDesc1")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("priPolTitle2")}</h3>
                    <p>{t("priPolDesc2")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("priPolTitle3")}</h3>
                    <p>{t("priPolDesc3")}</p>
                  </div>

                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("priPolTitle4")}</h3>
                    <p>{t("priPolDesc4")}</p>
                  </div>
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("priPolTitle5")}</h3>
                    <p>{t("priPolDesc5")}</p>
                  </div>
                  <div class="df-policy__item mb-35">
                    <h3 class="df-policy__title">{t("priPolTitle6")}</h3>
                    <p>{t("priPolDesc6")}</p>
                    <ul>
                      <li>{t("priPolDesc6A")}</li>
                      <li>{t("priPolDesc6B")}</li>
                      <li>{t("priPolDesc6C")}</li>
                      <li>{t("priPolDesc6D")}</li>
                      <li>{t("priPolDesc6E")}</li>
                      <li>{t("priPolDesc6F")}</li>
                    </ul>
                  </div>
                  <div class="df-policy__contact">
                    <h3 class="df-policy__title df-policy__title-2">
                      {t("priPolTitle7")}
                    </h3>
                    <p>{t("priPolDesc7")}</p>

                    <ul>
                      <li>
                        {t("priPolDesc7A")}

                        <span>
                          <a href="mailto:contact@bhutpurvsainikcanteen.com">
                            <span class="__cf_email__">
                              contact@bhutpurvsainikcanteen.com
                            </span>
                          </a>
                        </span>
                      </li>
                    </ul>

                    <div class="df-policy__address">
                      <p class="mb-0">
                        <a
                          href="https://www.google.com/maps?q=Opposite+to+Shinde+Hospital,+Gurunagar,+bhadrawati+442902"
                          target="_blank"
                        >
                          {t("priPolDesc7B")}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Privacypolicy;
