import React, { useState } from "react";
import Header from "../Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import { storage, database } from "../Firebaseconfig";
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
  listAll,
} from "firebase/storage";
import { ref as dbRef, push, set, onValue } from "firebase/database";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
function OurFrenchice() {
  const { t } = useTranslation();
  const franchiseData = [
    {
      img: "assets/img/newimages/img14.jpg",
      title: "Happy Franchise",
      description: "Showcase images of happy franchisees ",
      date: "01 Jan 2023",
    },
    {
      img: "assets/img/fra2.jpeg",
      title: " Location",
      description: "Franchise locations across different regions",
      date: "01 May 2023",
    },
    {
      img: "assets/img/newimages/img13.jpg",
      title: " Events",
      description: "Franchise events such as grand openings, anniversary ",
      date: "08 Sept 2023",
    },
    {
      img: "assets/img/newimages/img12.jpg",
      title: " Support",
      description: "Support and resources available to franchisees ",
      date: "27 May 2023",
    },
    {
      img: "assets/img/newimages/img11.jpg",
      title: " Benifits",
      description: "Benefits of franchising with Sainik Canteen",
      date: "28 Feb 2023",
    },
    {
      img: "assets/img/newimages/img10.jpg",
      title: " Team",
      description: "Team responsible for assisting franchisees with training",
      date: "19 April 2023",
    },
    {
      img: "assets/img/newimages/img9.jpg",
      title: " Oppurtunities",
      description: "Generate interest in franchise opportunities",
      date: "27 Nov 2023",
    },
    {
      img: "assets/img/newimages/img8.jpg",
      title: " Training",
      description: "Franchisee training sessions, workshops, and seminars ",
      date: "15 Aug 2023",
    },
    {
      img: "assets/img/newimages/img7.jpg",
      title: " Success Story",
      description: "Success stories and testimonials from franchisees ",
      date: "20 June 2023",
    },
  ];
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    franchise: "",
    selectdate: "",
    time: "",
    address: "",
    city:""
  });

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e.target);
    // For date inputs, use 'onInput' instead of 'onChange'
    const newValue = name === 'date' ? e.target.valueAsDate : value;
  
    setFormData({ ...formData, [name]: newValue });
    // Clear the error message when the user starts typing in a field
    setFormErrors({ ...formErrors, [name]: '' });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log("formData0", formData);

      // Validate the form
      const isValid = validateForm();

      if (isValid) {
        // Save form data if validation succeeds
        const formDataToSave = { ...formData };
        const contactsRef = dbRef(database, "Frenchise");
        const newContactRef = push(contactsRef);
          // Get the unique ID generated by push() method
      const FranchiceId = newContactRef.key;
  
      // Add the unique ID to the contact data
      formDataToSave.id = FranchiceId;
  
      // Save the contact data to the database
        await set(newContactRef, formDataToSave); 
        
        // Wait for the data to be saved
          setLoading(false);
        toast.success("Form submitted successfully!");
        console.log("Form submitted successfully:", formData);

        setFormData({
          name: "",
          email: "",
          number: "",
          franchise: "",
          selectdate: "",
          time: "",
          address: "",
          city:""
        });
      } else {
        console.log("Form validation failed. Please check the errors.");
        setLoading(false);

      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setLoading(false);

    }
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    // Validate name
    if (!formData.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    // Validate email
    if (!formData.email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      errors.email = "Email address is invalid";
      isValid = false;
    }

    // Validate number
    if (!formData.number) {
      errors.number = "Phone number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.number)) {
      errors.number = "Phone number is invalid";
      isValid = false;
    }

    // Validate franchise
    if (!formData.franchise) {
      errors.franchise = "Franchise is required";
      isValid = false;
    }

    if (!formData.city) {
      errors.city = "Area/City is required";
      isValid = false;
    }

    // Validate address
    if (!formData.address) {
      errors.address = "Address is required";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
    setLoading(false);
 
  };

  const BlogData = [
    {
      img: "assets/img/frenchice/franchicebanner.jpeg",
      title: t("blogPageTitlebutton1"),
      description: t("blogPageDesc1"),
      date: "01-01-2023",
    },

    {
      img: "assets/img/frenchice/frenchice2.jpeg",
      title: t("blogPageTitlebutton1"),
      description: t("blogPageDesc2"),
      date: "08-09-2023",
    },
   
  ];

  return (
    <>
      <Header />
      <main>
        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">{t("pageTitle3")}</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>{t("pageTitle")}</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>{t("pageTitle3")}</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="df-blog__area section-spacing p-relative fix">
          <div class="circle-2"></div>
          <div class="circle-3"></div>
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="section__title-wrapper section-title-spacing">
                  <h3 class="section__title mb-4">{t("ourFranchiseTitle1")}</h3>
                  <p>
                    {t("ourFranchiseDesc1")}
                    <br />
                    {t("ourFranchiseDesc1A")}
                    <br />
                    {t("ourFranchiseDesc1B")}
                  </p>
                </div>
              </div>
            </div>

            <div class="row g-5">
              <div class="col-lg-12">
                <div class="df-portfolio4__item-box">
                  <div data-aos="fade-right" class="df-portfolio4__item-thumb">
                    <a>
                      <img
                        className="imageresponsive"
                        style={{ width: "100%" }}
                        src="assets/img/newimages/img10.jpg"
                        alt="img not found"
                      />
                    </a>
                  </div>
                  <div data-aos="fade-left" class="df-portfolio4__item-content">
                    <div class="df-portfolio4__item-info">
                      <h4 class="df-portfolio4__item-title">
                        <a> {t("ourFranchiseTitle2")}</a>
                      </h4>
                      <p>{t("ourFranchiseDesc2")}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="df-portfolio4__item-box">
                  <div
                    data-aos="fade-right"
                    class="df-portfolio4__item-content"
                  >
                    <div class="df-portfolio4__item-info">
                      <h4 class="df-portfolio4__item-title">
                        <a> {t("ourFranchiseTitle3")}</a>
                      </h4>
                      <p>{t("ourFranchiseDesc3")}</p>
                    </div>
                  </div>
                  <div data-aos="fade-left" class="df-portfolio4__item-thumb">
                    <a>
                      <img
                        className="imageresponsive"
                        style={{ width: "100%" }}
                        src="assets/img/newimages/img11.jpg"
                        alt="img not found"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section class="df-blog__area section-spacing p-relative fix">
          <div class="circle-2"></div>
          <div class="circle-3"></div>

          <div class="container">
          <div><h3 className="text-center mb-30">{t("headerlist4")}</h3></div>

            <div class="row g-5">
              {BlogData.map((i) => (
                <div class="col-xl-6 col-lg-6 col-md-6">
                  <div class="df-blog2__box bg-2">
                    <div class="df-blog2__thumb">
                      <a>
                        <img src={i.img} alt="image not found"  style={{objectFit:'revert'}} />
                      </a>
                    </div>
                    <div class="df-blog2__content">
                      <div class="df-blog2__meta">
                        <a>
                          <span class="tag">{i.title}</span>
                        </a>
                        <span class="blog-date">{i.date}</span>
                      </div>

                      <h3 class="df-blog2__title">
                        <a
                          style={{
                            overflow: "hidden",
                            display: "-webkit-box",
                            webkitLineClamp: "1",
                            webkitBoxOrient: "vertical",
                          }}
                        >
                          {i.description}
                        </a>

                        {/* <a
                          className="blog_more_btn"
                          style={{ marginLeft: "10px" }}
                        >
                          <Link
                            style={{ textDecoration: "none" }}
                            to="/blogdetails"
                          >
                            <span class="tag"> {t("blogPageReadmorebut")}</span>
                          </Link>
                        </a> */}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}

              {/* <div
                class="df-blog2__area-btn text-center mt-60 wow fadeInUp"
                data-wow-delay=".3s"
              >
                <Link to="/blogdetails" class="load-btn">
                  Load More<i class="fa-duotone fa-spinner"></i>
                </Link>
              </div> */}
            </div>
          </div>
        </section>

        <section class="contact-box-style " style={{ paddingBottom: "100px" }}>
          <div class="container">
            <div
              className="frnch_frm"
              style={{
                boxShadow:
                  " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                padding: " 40px",
                borderRadius: "15px",
              }}
            >
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-10 col-md-12">
                  <div class="df-booking__form">
                    <h3 class="df-booking__form-title text-center">
                      {t("pageTitle7")}
                    </h3>
                    <form onSubmit={handleSubmit}>
                      <div className="row gx-5">
                        <div className="col-md-6">
                          <div className="df-input-field">
                            <label htmlFor="name">Your Name*</label>
                            <input
                              type="text"
                              id="name"
                              name="name"
                              value={formData.name}
                              onChange={handleChange}
                            />
                            {formErrors.name && (
                              <span className="error">{formErrors.name}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="df-input-field">
                            <label htmlFor="email">Your Email*</label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              onChange={handleChange}
                            />
                            {formErrors.email && (
                              <span className="error">{formErrors.email}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="df-input-field">
                            <label htmlFor="number">Your Phone*</label>
                            <input
                              type="number"
                              id="number"
                              name="number"
                              value={formData.number}
                              onChange={handleChange}
                            />
                            {formErrors.number && (
                              <span className="error">{formErrors.number}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="df-input-field">
                            <label htmlFor="franchise">Location*</label>
                            <input
                              type="text"
                              id="franchise"
                              name="franchise"
                              value={formData.franchise}
                              onChange={handleChange}
                            />
                            {formErrors.franchise && (
                              <span className="error">
                                {formErrors.franchise}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="df-input-field field-date">
                            <label htmlFor="date">Date of Visit*</label>
                          
   <input
   type="date"
   id="date"
   name="selectdate"
   value={formData.selectdate}
   onChange={handleChange}
/>

                             {formErrors.date && (
                              <span className="error">{formErrors.date}</span>
                            )} 
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="df-input-field field-time">
                            <label htmlFor="time">Time of Visit*</label>
                            <input
                              type="time"
                              id="time"
                              name="time"
                              value={formData.time}
                              onChange={handleChange}
                            />
                            {formErrors.time && (
                              <span className="error">{formErrors.time}</span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="df-input-field">
                            <label htmlFor="address">House Address*</label>
                            <input
                              type="text"
                              id="address"
                              name="address"
                              value={formData.address}
                              onChange={handleChange}
                            />
                            {formErrors.address && (
                              <span className="error">
                                {formErrors.address}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="df-input-field">
                            <label htmlFor="franchise">Area/City*</label>
                            <input
                              type="text"
                              id="franchise"
                              name="city"
                              value={formData.city}
                              onChange={handleChange}
                            />
                            {formErrors.franchise && (
                              <span className="error">
                                {formErrors.franchise}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          {
                            loading ? <>
                            <div className="loader-container">
  <div className="loader"></div>
</div>
                            </> :
 <div className="df-booking__form-btn mt-10">
 <button type="submit" className="primary-btn">
   send now
   <span className="icon__box">
     <img
       className="icon__first"
       src="assets/img/icon/arrow-white.webp"
       alt="image not found"
     />
     <img
       className="icon__second"
       src="assets/img/icon/arrow-white.webp"
       alt="image not found"
     />
   </span>
 </button>
</div>
                          }
                         
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}

export default OurFrenchice;
