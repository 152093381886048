import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Footer from "./Footer";

const Vendorregistration = () => {
  return (
    <>
      <Header />
      <main>
        <div class="adjust-header-space bg-common-white"></div>

        <section class="page-title-area-2 breadcrumb-spacing bg-theme-4 section-spacing">
          <div class="d-none" data-background="d-none"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-xxl-9">
                <div class="page-title-wrapper-2 text-center">
                  <h1 class="page__title-2 mb-15">Vendor Registration</h1>
                  <div class="breadcrumb-menu-2 d-flex justify-content-center">
                    <nav
                      aria-label="Breadcrumbs"
                      class="breadcrumb-trail breadcrumbs"
                    >
                      <ul class="trail-items-2">
                        <li class="trail-item-2 trail-begin">
                          <Link to="/">
                            <span>Home</span>
                          </Link>
                        </li>
                        <li class="trail-item-2 trail-end">
                          <span>Vendor Registration</span>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="contact-box-style section-spacing">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="section__title-wrapper text-center section-title-spacing">
                  <h3 class="section__title mb-4">Our Vendorship</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                boxShadow:
                  " rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                padding: " 40px",
                borderRadius: "15px",
              }}
            >
              <div class="row justify-content-center">
                <div class="col-xl-12 col-lg-8 col-md-12">
                  <div class="df-booking__form">
                    <h3 class="df-booking__form-title text-center">
                      Apply For Vendorship
                    </h3>
                    <form action="#">
                      <div class="row gx-5">
                        <div class="col-md-6">
                          <div class="df-input-field">
                            <label for="name">Your Name*</label>
                            <input type="text" id="name" name="name" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="df-input-field">
                            <label for="email">Your Email*</label>
                            <input type="text" id="email" name="email" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="df-input-field">
                            <label for="number">Your Phone*</label>
                            <input type="text" id="number" name="number" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="df-input-field">
                            <label for="service">Select Service*</label>
                            <select name="service" id="service">
                              <option value="plumbing">Plumbing</option>
                              <option value="cleaning">Cleaning</option>
                              <option value="cleaning">Pipe Leak</option>
                              <option value="cleaning">Drain</option>
                              <option value="cleaning">Gasoline</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="df-input-field field-date">
                            <label for="date">Date of Visit*</label>
                            <input
                              type="text"
                              id="date"
                              class="bd-date-picker"
                              value="18-07-2023"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="df-input-field field-time">
                            <label for="time">Time of Visit*</label>
                            <input
                              type="time"
                              id="time"
                              name="time"
                              value="10:30"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="df-input-field">
                            <label for="address">House Address*</label>
                            <input type="text" id="address" name="address" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="df-booking__form-btn mt-10">
                            <button type="submit" class="primary-btn">
                              send now
                              <span class="icon__box">
                                <img
                                  class="icon__first"
                                  src="assets/img/icon/arrow-white.webp"
                                  alt="image not found"
                                />
                                <img
                                  class="icon__second"
                                  src="assets/img/icon/arrow-white.webp"
                                  alt="image not found"
                                />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Vendorregistration;
