import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function HomeReadBlogPage() {
  const { t } = useTranslation();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const blogData = [
    {
      id: 1,
      // image: "assets/img/HomeBlog.jpg",
      // tag: t("homeReadBlogTopic1"),
      // date: t("homeReadBlogDate1"),
      // title: t("homeReadBlogDesc1"),

      image: "assets/img/customblog/blog1custome.jpg",
      tag: t("blogPageTitlebutton2"),
      date: "26-07-2024",
      title: t("blogPageDesc1"),
     url: "Kargil-Vijay-Diwas-2024", // URL slug without '/blogdetails'
    },
    {
      id: 2,
      image: "assets/img/customblog/sanketkaleji.JPG",
      tag: t("blogPageTitlebutton2"),
      title: t("blog2maintitle"),
      date: "26-07-2023",
      url: "/Celebrating-25th-Kargil-Vijay-Diwas", // URL for navigation
    },
    {
      id: 3,
      image: "assets/img/IMG_3743.JPG",
      tag: t("homeReadBlogTopic3"),
      date: t("homeReadBlogDate3"),
      title: t("homeReadBlogDesc3"),
    },
    {
      id: 4,
      image: "assets/img/HomeBlog2.jpg",
      tag: t("homeReadBlogTopic4"),
      date: t("homeReadBlogDate4"),
      title: t("homeReadBlogDesc1"),
    },
    // Add more blog data as needed
  ];

  const CustomLeftArrow = ({ onClick, ...rest }) => (
    <div
      onClick={() => onClick()}
      style={{
        position: "absolute",
        top: "50%",
        left: "0",
        transform: "translateY(-50%)",
        cursor: "pointer",
      }}
      {...rest}
    >
      <i className="icon-022-left"></i>
    </div>
  );

  const CustomRightArrow = ({ onClick, ...rest }) => (
    <div
      onClick={() => onClick()}
      style={{
        position: "absolute",
        top: "50%",
        right: "0",
        transform: "translateY(-50%)",
        cursor: "pointer",
      }}
      {...rest}
    >
      <i className="icon-021-next"></i>
    </div>
  );

  return (
    <div>
      <section className="df-blog__area section-spacing-top mb-60 pt-40">
        <div className="container">
          <div
            className="row align-items-end section-title-spacing g-5 wow fadeInUp"
            data-wow-delay=".3s"
            style={{
              visibility: "visible",
              animationDelay: "0.3s",
              animationName: "fadeInUp",
            }}
          >
            <div className="col-lg-8 col-md-8">
              <div className="section__title-wrapper">
                <span className="section__subtitle bg-lighter">
                  {t("homeReadBlogSubTitle")}
                </span>
                <h2 className="section__title"> {t("homeReadBlogTitle")}</h2>
              </div>
            </div>
          </div>

          <Carousel
            responsive={responsive}
            infinite
            containerClass="swiper blog3__slider wow fadeInUp"
            itemClass="swiper-slide"
          >
            {blogData.map((blog , index) => (
              <div
                key={blog.id}
                className="df-blog2__box df-shadow"
                style={{ margin: "10px" }}
              >
                <div className="df-blog2__thumb">
                  <Link 
                  
                  to={index === 0 
                    ? `/Kargil-Vijay-Diwas-2024` 
                    : index === 1 
                    ? `/Celebrating-25th-Kargil-Vijay-Diwas`
                    : `/blogdetails`}

                  >
                    <img
                      style={{ height: "277px", width: "100%" }}
                      src={blog.image}
                      alt="image not found"
                    />
                  </Link>
                </div>
                <div className="df-blog2__content">
                  <div className="df-blog2__meta">
                    <>
                      <span className="tag">{blog.tag}</span>
                    </>
                    <span className="blog-date">{blog.date}</span>
                  </div>
                  <h3 className="df-blog2__title">
                    <div
                      style={{
                        overflow: "hidden",
                        WebkitLineClamp: "1",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {blog.title}
                    </div>
                  </h3>
                  <a className="blog_more_btn" style={{ marginLeft: "10px" }}>
                    <Link style={{ textDecoration: "none" }}  to={index === 0 
                    ? `/Kargil-Vijay-Diwas-2024` 
                    : index === 1 
                    ? `/Celebrating-25th-Kargil-Vijay-Diwas`
                    : `/blogdetails`}
                    >
                      <span class="tag">{t("homeReadBlogButton1")}</span>
                    </Link>
                  </a>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </div>
  );
}

export default HomeReadBlogPage;
